import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import FloatingButtons from "./frontend/FloatingButtons";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <FloatingButtons />
      <section className="cs-bg-dark py-4 cs-section">
        <div className="container">
          <Row className="mb-4">
            <Col>
              <Row>
                <Col>
                  <img
                    className="rounded"
                    width={"100%"}
                    src="/assets/frontend/images/statsBg.webp"
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <h1 className="text-white">ABOUT US</h1>
                  <p className="text-white">
                    BBR is not just a booking platform; it&#39;s a dynamic
                    community that fuels your passion for life on the water.
                    Whether you&#39;re chasing trophy bass in a tournament-ready
                    bass boat, seeking high- speed excitement on a jet ski, or
                    enjoying a relaxing cruise on a pontoon boat or fishing
                    barge, BBR connects you with the perfect watercraft for any
                    adventure. We offer sport fishing vessels, leisure craft,
                    cruisers, and kayaks, making it easier than ever to explore
                    lakes, rivers, and coastal waters nationwide.
                    <br />
                    <br />
                    Our CEO and founder launched BBR in 2021 with a bold vision:
                    to make watercraft rentals accessible on every major
                    waterway in the USA. More than just a rental service, BBR is
                    a community of anglers, adventurers, and boating enthusiasts
                    who share a love for the water. Whether you&#39;re renting,
                    listing your own boat, or booking a guided fishing trip,
                    your next great adventure begins with BBR.
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <h1 className="text-white">
                    FIND A FISHING GUIDE ANYWHERE YOU TRAVEL
                  </h1>
                  <p className="text-white">
                    Looking to book a fishing trip in a new destination? BBR
                    provides a platform for experienced fishing guides across
                    the country, ensuring you have access to the best local
                    experts wherever you&#39;re headed. Whether you&#39;re after
                    deep-sea sport fish, freshwater bass, or inshore favorites,
                    we make it easy to find and book a top-tier fishing guide
                    specific to your location. Enjoy a hassle-free experience
                    and fish with confidence, knowing you&#39;re in the hands of
                    a skilled professional.
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <h2 className="text-white">OUR SERVICES INCLUDE:</h2>
                  <ul className="text-white">
                    <li>Tournament-ready bass boat rentals with trailer</li>
                    <li>A robust &amp; streamlined online booking platform</li>
                    <li>Online check-in and waiver system for easy access</li>
                    <li>
                      Renter verification and approval system to ensure our boat
                      operators are experienced
                    </li>
                    <li>
                      A wide variety of watercraft rentals including kayaks, jet
                      skis, sport fishing vessels, pontoons, fishing barges,
                      leisure craft, and cruisers
                    </li>
                    <li>Delivery or valet launch and load options available</li>
                    <li>Flexible rental durations</li>
                    <li>
                      From high-speed thrills to laid-back cruising, BBR Boat
                      Rentals makes getting on the water effortless—wherever
                      your adventure takes you!
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <Col>
                      <img
                        className="rounded"
                        width={"100%"}
                        src="/assets/frontend/images/takewithyou.webp"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <h2 className="text-white">Take it with you!</h2>
                      <p className="text-white">
                        Embark on your bass fishing adventure with BBR Bass Boat
                        Rentals, where you can not only rent a tournament ready
                        bass boat but also trailer it to your chosen inland
                        freshwater haven. As our fleet grows to match your
                        desires, stay tuned for listings near your favorite
                        lake.{" "}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className="margin-top-mobile">
                  <Row>
                    <Col>
                      <img
                        className="rounded"
                        width={"100%"}
                        src="/assets/frontend/images/ready.webp"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <h2 className="text-white">Tournament ready!</h2>
                      <p className="text-white">
                        Discover the thrill of tournament ready bass boats on
                        our booking platform, where we set a high standard for
                        features to be considered tournament ready. At BBR,
                        we're committed to surpassing expectations for both boat
                        owners and renters. To dive into this unparalleled
                        experience, ensure you're verified – check out our "GET
                        VERIFIED" section for more details. Your next bass
                        fishing journey starts with BBR!{" "}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="mt-4">
                  <Row>
                    <Col>
                      <img
                        className="rounded"
                        width={"100%"}
                        src="/assets/frontend/images/boat.webp"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <h2 className="text-white">
                        What To Expect From Your Rental Boat
                      </h2>
                      <ul className="text-white">
                        <li>
                          Bass boat including trailer for overnight rentals
                        </li>
                        <li>Up to 250HP outboard motor</li>
                        <li>
                          Tournament ready including, horn/whistle, fire
                          extinguisher, nav lights, throwable PFD, life jackets,
                          Kill switch and lanyard
                        </li>
                        <li>Industry recognized marine electronics</li>
                        <li>Live wells</li>
                        <li>Hot foot available</li>
                        <li>Shallow water anchors available</li>
                        <li>GPS spot lock trolling motors available</li>
                        <li>FFS available</li>
                        <li>Lockable rod storage</li>
                        <li>Butt seat available</li>
                        <li>Safety response equipment available</li>
                        <li>First aid kit available</li>
                        <li>Boat cover available</li>
                        <li>
                          Overnight trailer package includes vehicle safety kit,
                          tire change kit, boat maintenance and wipe down kit,
                          power cable. (available means check with the boat
                          owner listing)
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
