import React, { useState } from "react";
import { Nav, NavDropdown, Container, Navbar } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function Header() {
  const auth = useAuth();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarOpen(!isNavbarOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch {
      console.log("Failed to log out");
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary navbar-dark header cs-bg-dark"
    >
      <Container>
        <Navbar.Brand>
          <Link to={"/"}>
            <img
              width={150}
              src="/assets/backend/images/logo.webp"
              alt="Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={handleNavbarToggle}
        />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-between"
        >
          <Nav className="cs-nav">
            <Link
              to="/home"
              className="cs-tx-light nav-link"
              onClick={handleNavbarToggle}
            >
              Home
            </Link>
            <Link
              to="/listings"
              className="cs-tx-light nav-link"
              onClick={handleNavbarToggle}
            >
              Booking
            </Link>
            <Link
              to="/about"
              className="cs-tx-light nav-link"
              onClick={handleNavbarToggle}
            >
              About
            </Link>
            <Link
              to="/pricing"
              className="cs-tx-light nav-link"
              onClick={handleNavbarToggle}
            >
              Pricing
            </Link>
            <Link
              to="/get-verified"
              className="cs-tx-light nav-link"
              onClick={handleNavbarToggle}
            >
              Get Verified
            </Link>
            <Link
              to="/faq"
              className="cs-tx-light nav-link"
              onClick={handleNavbarToggle}
            >
              FAQ
            </Link>
            <Link
              to="/contact"
              className="cs-tx-light nav-link"
              onClick={handleNavbarToggle}
            >
              Contact Us
            </Link>
            <Link
              to="/how-it-works"
              className="cs-tx-light nav-link"
              onClick={handleNavbarToggle}
            >
              How It Works
            </Link>
            <NavDropdown
              title="More"
              className="cs-tx-light"
              id="collapsible-nav-dropdown"
            >
              <div className="d-flex flex-column dropdown-div">
                <Link to="/cancellation" className="text-dark">
                  Cancellation
                </Link>
                <Link to="/privacy-policy" className="text-dark">
                  Privacy Policy
                </Link>
                <Link to="/cookie-policy" className="text-dark">
                  Cookie Policy
                </Link>
                <Link to="/client-relationship" className="text-dark">
                  Client Relationship
                </Link>
                <Link
                  to="https://tpwd.texas.gov/regulations/outdoor-annual/"
                  className="text-dark"
                  target="_blank"
                >
                  TPWD Outdoor Annual
                </Link>
                <Link
                  to="https://www.takemefishing.org/"
                  className="text-dark"
                  target="_blank"
                >
                  Fishing License
                </Link>
                <Link
                  to="https://www.boatus.org/"
                  className="text-dark"
                  target="_blank"
                >
                  Boaters Education
                </Link>
                <Link
                  to="https://www.youtube.com/watch?v=fLtisa4qC10"
                  className="text-dark"
                  target="_blank"
                >
                  Boating Safety Video
                </Link>
                <Link
                  to="https://www.youtube.com/watch?v=IPAYWGsYJ5M"
                  className="text-dark"
                  target="_blank"
                >
                  Launch And Load The Boat
                </Link>
                <Link
                  to="https://tpwd.texas.gov/publications/pwdpubs/media/pwd_bk_l2000_0014.pdf#:~:text=1%2C%201975.&text=It%20is%20the%20duty%20of,laws%20relating%20to%20water%20safety."
                  className="text-dark"
                  target="_blank"
                >
                  Texas Water Safety Act
                </Link>
                <Link to="/launch-load-checklists" className="text-dark">
                  Launch & Load Checklists
                </Link>
                <Link to="/my-waiver" className="text-dark">
                  My Signed Waivers
                </Link>
                <Link to="/waivers/view-waivers" className="text-dark">
                  Submit Waivers
                </Link>
              </div>
            </NavDropdown>
            {auth.currentUser ? (
              <>
                <Link to="/dashboard" className="cs-tx-light nav-link">
                  Dashboard
                </Link>
                <Link onClick={handleLogout} className="cs-tx-light nav-link">
                  Logout
                </Link>
              </>
            ) : (
              <Link to="/auth" className="cs-tx-light nav-link">
                Login
              </Link>
            )}
          </Nav>
          <Nav>
            <div className="social-icons">
              <Link
                to="https://www.facebook.com/BBRBassboatrentals/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </Link>
              <Link
                to="https://www.instagram.com/bbrbassboatrentals/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCgOQSUpJj1MkRkt6-tJZJFg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube />
              </Link>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
