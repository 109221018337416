import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import FloatingButtons from "./FloatingButtons";

export default function ClientRelationship() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const videos = [
    {
      id: "9wT2ERN6MfI",
      title: "BBR BASS BOAT RENTALS EXPLAINER",
    },
    {
      id: "isx-4vVuSAo",
      title: "HOW TO LIST YOUR BOAT",
    },
    {
      id: "Mat_3ebd2HI",
      title: "HOW TO RENT A BASS BOAT",
    },
    {
      id: "bwHvnYlpAZE",
      title: "HOW TO GET VERIFIED",
    },
  ];

  return (
    <>
      <FloatingButtons />
      <section className="cs-bg-dark py-4 cs-section text-white privacy-policy">
        <div className="container">
          <Row className="mb-4">
            <Col>
              <Row className="mt-4">
                <Col>
                  <h1>How the platform works</h1>
                </Col>
              </Row>
              {videos.map((video, index) => (
                <Row className="mt-4" key={index}>
                  <h3 className="how-to-title text-white mb-2">
                    {video?.title}
                  </h3>
                  <Col>
                    <div
                      className="how-to-videos-wrapper relative"
                      style={{ paddingBottom: "40.25%", height: 0 }}
                    >
                      <iframe
                        className="how-to-videos absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
                        src={`https://www.youtube.com/embed/${video?.id}`}
                        title={`YouTube video player ${index + 1}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        style={{
                          width: "800px",
                          height: "450px",
                        }}
                        allowFullScreen
                      ></iframe>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
