import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";

export class GoogleMap extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    const locations = [
      {
        name: "Bondi Beach",
        lat: -33.890542,
        lng: 151.274856,
        link: "/bondi-page",
      },
      {
        name: "Coogee Beach",
        lat: -33.923036,
        lng: 151.259052,
        link: "/coogee-page",
      },
      {
        name: "Cronulla Beach",
        lat: -34.028249,
        lng: 151.157507,
        link: "/cronulla-page",
      },
      {
        name: "Manly Beach",
        lat: -33.80010128657071,
        lng: 151.28747820854187,
        link: "/manly-page",
      },
      {
        name: "Maroubra Beach",
        lat: -33.950198,
        lng: 151.259302,
        link: "/maroubra-page",
      },
    ];

    const { boatLocations } = this.props;

    console.log("locations", locations);
    console.log("boatLocations", boatLocations);

    return (
      <div className="width-100">
        <Map
          google={this.props.google}
          zoom={4}
          initialCenter={{ lat: 39.8283, lng: -98.5795 }}
          className="w-100 h-100"
          onReady={this.handleMapReady}
          options={{
            mapTypeControl: false, // Hide map type control if not needed
            zoomControl: true,
            fullscreenControl: true,
            streetViewControl: true,
          }}
        >
          {boatLocations &&
            boatLocations != undefined &&
            boatLocations.map((location, index) => (
              <Marker
                key={index}
                position={{ lat: location.lat, lng: location.lng }}
                onClick={this.onMarkerClick}
                name={location.name}
                link={location.link} // Pass the link as a prop to the Marker component
                icon={{
                  url: "https://www.bbrbassboatrentals.com/images/bassboat.png",
                  scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
                }}
              />
            ))}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onClose}
          >
            <div>
              <h5>{this.state.selectedPlace.name}</h5>
              <a href={this.state.selectedPlace.link}>View Boat</a>{" "}
              {/* Use the link from the selected place */}
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg",
})(GoogleMap);
