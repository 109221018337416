import React from 'react'
import { Link, useNavigate,useLocation } from 'react-router-dom';

export default function AppHeader({ onSidebarToggle }) {

    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
      navigate(-1); // Navigate back one step in history
    };

    const openSidebar = () => {
        console.log('Clicked');
        onSidebarToggle();
    };

  return (
    <>
      <nav className="navbar navbar-light navbar-expand-lg">
        <div className="container-fluid">
          <div className="navbar-nav" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link hide-sidebar-toggle-button"
                  to="#"
                  onClick={goBack}
                >
                  <i className="material-icons">first_page</i>
                </Link>
              </li>
              <li className="nav-item mobile-only">
                <Link
                  className="nav-link hide-sidebar-toggle-button"
                  onClick={openSidebar}
                >
                  <i className="material-icons">menu_open</i>
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex">
            <ul className="navbar-nav">
              <li className="nav-item hidden-on-mobile">
                <Link
                  to="/"
                  exact
                  className={`nav-link text-black ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <span className="text-black">Back to website</span>
                </Link>
              </li>
              <li className="nav-item hidden-on-mobile">
                <Link
                  to="/dashboard/inbox"
                  exact
                  className={`nav-link text-black ${
                    location.pathname === "/dashboard/inbox" ? "active" : ""
                  }`}
                >
                  <span className="text-black">Inbox</span>
                </Link>
              </li>
              <li className="nav-item hidden-on-mobile">
                <Link
                  to="/dashboard/bookings"
                  exact
                  className={`nav-link text-black ${
                    location.pathname === "/dashboard/bookings" ? "active" : ""
                  }`}
                >
                  <span>Bookings</span>
                </Link>
              </li>
              <li className="nav-item hidden-on-mobile">
                <Link
                  to="/dashboard/payments"
                  exact
                  className={`nav-link text-black ${
                    location.pathname === "/dashboard/payments" ? "active" : ""
                  }`}
                >
                  <span>Payments</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
