// SignupOwner.js
import {
  getDatabase,
  ref,
  get,
  push,
  set,
  update,
  serverTimestamp,
} from "firebase/database";

import { useAuth } from "../contexts/AuthContext";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import {
  Button,
  Form,
  ProgressBar,
  Row,
  Col,
  Container,
  Image,
} from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IoClose } from "react-icons/io5";
import TextField from "@mui/material/TextField";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { FaMapPin, FaStar } from "react-icons/fa";
import { FaArrowRightLong, FaPeopleGroup } from "react-icons/fa6";
import { CiShoppingTag, CiCalendar } from "react-icons/ci";
import { RxWidth } from "react-icons/rx";
import ImageSlider from "./ImageSlider";
import TwoMonthCalendar from "./TwoMonthCalendar";
import {
  getUserByID,
  getEventsByListingId,
  getReviewsByListingId,
  sendEmail,
  getCurrentUserDetails,
  InquiryReceivedEmailBody,
  getPlatformHourlyRate,
  sendSMS,
  GetVerifiedForBassBoat,
  getListingByID,
} from "./utils";
import dayjs from "dayjs";
import { Alert } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Lightbox from "react-awesome-lightbox";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { TbSteeringWheel } from "react-icons/tb";

const SingleListingLayout = ({ initialData, reviews }) => {
  const { signup, login } = useAuth();
  const [cleared, setCleared] = useState(false);
  const [phone, setPhone] = useState("");
  const [listingReviews, setListingReviews] = useState("");
  const [listingLocation, setListingLocation] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [isCaptain, setIsCaptain] = useState(false);
  const [listing, setListing] = useState([]);
  const [sameDayHours, setSameDayHours] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSameDayHours = (value) => {
    setSameDayHours(value);
    if (value >= 24) {
      setTrailerCheckbox(true);
    }
  };

  const getLocationName = async (dropoff) => {
    try {
      const dropoffLat = dropoff.lat;
      const dropoffLlng = dropoff.lng;

      const dropOffResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${dropoffLat},${dropoffLlng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
      );

      const dropOffdata = await dropOffResponse.json();
      const dropoffName =
        dropOffdata.results[0]?.formatted_address || "Location not found";
      setListingLocation(dropoffName);
    } catch (error) {
      console.error("Error fetching location name:", error);
      setListingLocation("Location not found");
    }
  };

  const handlePhoneChange = (newPhone) => {
    setPhone(newPhone);
    const validation = matchIsValidTel(newPhone);
    setValidPhone(validation);
    setSignUpData((prevData) => ({
      ...prevData,
      phone: newPhone,
    }));

    console.log(signUpData);
  };
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
    // Validate email using the specified pattern
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setValidEmail(emailPattern.test(enteredEmail));

    setSignUpData((prevData) => ({
      ...prevData,
      email: email,
    }));
  };

  const [loading, setLoading] = useState(false);

  const [deliveryPickupCheckbox, setDeliveryPickupCheckbox] = useState(false);
  const [trailerCheckbox, setTrailerCheckbox] = useState(false);
  const [captainCheckbox, setCaptainCheckbox] = useState(false);

  const [acknowledgement1, setAcknowledgement1] = useState(true);
  const [acknowledgement2, setAcknowledgement2] = useState(false);

  const handleAcknowledgement = (acknowledgementName) => {
    if (acknowledgementName === "acknowledgement1") {
      setAcknowledgement1((prev) => !prev);
    } else if (acknowledgementName === "acknowledgement2") {
      setAcknowledgement2((prev) => !prev);
    }
  };

  const handleDeliveryPickupCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setDeliveryPickupCheckbox(isChecked);

    // Update inquiryData for Delivery/Pickup
    setInquiryData((prevData) => ({
      ...prevData,
      additionals: {
        ...prevData.additionals,
        deliveryPickup: isChecked ? 400 : 0, // Update the price accordingly
      },
    }));
  };

  const handleTrailerCheckbox = (event) => {
    const isChecked = event.target.checked;
    setTrailerCheckbox(isChecked);

    // Update inquiryData for Trailer
    setInquiryData((prevData) => ({
      ...prevData,
      additionals: {
        ...prevData.additionals,
        trailer: isChecked ? 100 : 0, // Update the price accordingly
      },
    }));
  };

  const handleCaptainCheckbox = (event) => {
    const isChecked = event.target.checked;
    setCaptainCheckbox(isChecked);

    // Update inquiryData for Captain
    setInquiryData((prevData) => ({
      ...prevData,
      additionals: {
        ...prevData.additionals,
        captain: isChecked ? 1 : 0,
      },
    }));
  };

  useEffect(() => {
    if (isCaptain) {
      setInquiryData((prevData) => ({
        ...prevData,
        additionals: {
          ...prevData.additionals,
          captain: 1,
        },
      }));
    }
  }, [isCaptain]);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const newMessage = `Hello ${ownerDetails.firstName} ${
        ownerDetails.lastName
      }, 
        LineBreakI am reaching out to express my interest in <Link to='/listing/${listingId}>'${
        formData.listingTitle
      }</Link> and have a few inquiries regarding your listing.
        LineBreakLineBreak<span style="font-weight: bold;">My Requirements are:</span>LineBreak
        ${
          inquiryData.prefferedDate !== null
            ? `• My preffered date is, ${inquiryData.prefferedDate}LineBreak`
            : ""
        }
        ${
          inquiryData.prefferedTime !== null
            ? `• My preffered time is, ${inquiryData.prefferedTime}LineBreak`
            : ""
        }
        ${
          inquiryData.overnight > 0
            ? `• Duration, ${inquiryData.overnight} NightsLineBreak`
            : ""
        }
        ${
          inquiryData.sameDayHours > 0
            ? `• Duration, ${inquiryData.sameDayHours} Hours `
            : ""
        }
        ${
          inquiryData.sameDayMinutes > 0
            ? `${inquiryData.sameDayMinutes} MinutesLineBreak`
            : ""
        }
        ${
          inquiryData.children > 0
            ? `• ${inquiryData.children} ChildrenLineBreak`
            : ""
        }
        ${
          inquiryData.infants > 0
            ? `• ${inquiryData.infants} InfantsLineBreak`
            : ""
        }
        ${
          inquiryData.adults > 0
            ? `• ${inquiryData.adults} AdultsLineBreak`
            : ""
        }
        ${
          inquiryData.seniors > 0
            ? `• ${inquiryData.seniors} SeniorsLineBreak`
            : ""
        }
        ${inquiryData.total > 0 ? `• ${inquiryData.total} TotalLineBreak` : ""}
        LineBreakLineBreak<span style="font-weight: bold;">Comments:</span>LineBreak
        ${
          inquiryData.comments !== null
            ? `${inquiryData.comments}LineBreak`
            : ""
        }`;

      setMessage(newMessage);
      setError("");
      setLoading(true);

      // Signup the user using email and password
      const userCredential = await signup(
        signUpData.email,
        signUpData.password
      );
      const userId = userCredential.user.uid; // Assuming Firebase auth returns a user object with uid
      await sendInquiry(true, userId);

      // Store user information in the Realtime Database under 'users' node
      const userRef = ref(getDatabase(), `users/${userId}`);
      await set(userRef, {
        firstName: signUpData.firstName,
        lastName: signUpData.lastName,
        email: signUpData.email,
        phone: signUpData.phone,
        role: "Renter",
        status: "Pending",
      });

      // Store userMeta information under 'userMeta' node with the same user ID
      const userMetaRef = ref(getDatabase(), `userMeta/${userId}`);
      await set(userMetaRef, {
        age: "12",
        userAnswers: {
          // Assuming multiChoiceQuestions and textQuestionAnswers are arrays of objects
          multiChoiceQuestions: {
            question: "question",
            answer: "answer", // Assuming selectedOptions is an array of selected options
          },
          textQuestions: {
            question: "question",
            answer: "answer",
          },
        },
        // Add other userMeta data fields as needed
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const handleAdd = (field) => {
    if (field === "sameDayHours") {
      setInquiryData((prevData) => ({
        ...prevData,
        sameDayHours: inquiryData.sameDayHours + 1,
      }));
      if (sameDayHours >= 24) {
        setTrailerCheckbox(true);
      }
      setInquiryData((prevData) => ({
        ...prevData,
        overnight: 0,
      }));
    }
  };

  const handleRemove = (field) => {
    if (field === "sameDayHours" && inquiryData.sameDayHours > 0) {
      setInquiryData((prevData) => ({
        ...prevData,
        sameDayHours: inquiryData.sameDayHours - 1,
      }));
      if (sameDayHours >= 24) {
        setTrailerCheckbox(true);
      }
    }
  };

  const sendInquiry = async (newUser, userId) => {
    console.log(auth);
    console.log("inquiryData");
    console.log(inquiryData);

    if (
      acknowledgement1 != true ||
      acknowledgement2 != true ||
      (inquiryData.sameDayHours <= 0 && inquiryData.overnight <= 0) ||
      inquiryData.prefferedDate == null ||
      inquiryData.prefferedTime == null
    ) {
      setError("Please fill the fields and acknowledge before submitting");
      return;
    }

    const newMessage = `Hello ${ownerDetails.firstName} ${
      ownerDetails.lastName
    }, I have an inquiry for ${formData.listingTitle}.
        LineBreakLineBreak<span style="font-weight: bold;">My Requirements are:</span>LineBreak
        ${
          inquiryData.prefferedDate !== null
            ? `• I require the boat preferably on, ${inquiryData.prefferedDate}LineBreak`
            : ""
        }
        ${
          inquiryData.prefferedTime !== null
            ? `• My preffered time is, ${inquiryData.prefferedTime}LineBreak`
            : ""
        }
        ${
          inquiryData.overnight > 0
            ? `• Duration, ${inquiryData.overnight} NightsLineBreak`
            : ""
        }
        ${
          inquiryData.sameDayHours > 0
            ? `• Duration, ${inquiryData.sameDayHours} Hours`
            : ""
        }
        ${
          inquiryData.sameDayMinutes > 0
            ? ` ${inquiryData.sameDayMinutes} MinutesLineBreak`
            : ""
        }
        ${inquiryData.total > 0 ? `• ${inquiryData.total} Total` : ""}
        ${inquiryData.children > 0 ? `• ${inquiryData.children} Children` : ""}
        ${inquiryData.infants > 0 ? `• ${inquiryData.infants} Infants` : ""}
        ${inquiryData.adults > 0 ? `• ${inquiryData.adults} Adults` : ""}
        ${inquiryData.seniors > 0 ? `• ${inquiryData.seniors} Seniors` : ""}
        LineBreakLineBreak<span style="font-weight: bold;">Comments:</span>LineBreak
        ${
          inquiryData.comments !== null
            ? `${inquiryData.comments}LineBreak`
            : ""
        }
        LineBreakLineBreak<span style="font-weight: bold;">Additionals:</span>LineBreak
        ${
          deliveryPickupCheckbox
            ? "• Delivery and pickup to 130 miles.LineBreak"
            : ""
        }
        ${trailerCheckbox ? "• Trailer Package.LineBreak" : ""}
        ${captainCheckbox ? "• Captain Provided.LineBreakLineBreak" : ""}
        `;

    setMessage(newMessage);

    const database = getDatabase();

    const chatId1 = `${userId}_${formData.ownerId}`;
    const chatId2 = `${formData.ownerId}_${userId}`;

    const chatRef1 = ref(database, `chats/${chatId1}`);
    const chatRef2 = ref(database, `chats/${chatId2}`);

    if (userId === formData.ownerId) {
      alert("You cannot send a message to yourself");
      return;
    }

    try {
      const chatSnapshot1 = await get(chatRef1);
      const chatSnapshot2 = await get(chatRef2);
      console.log("chatSnapshot1 messages:", chatSnapshot1);
      console.log("chatSnapshot2 messages:", chatSnapshot2);

      let chatId;

      if (chatSnapshot1.exists()) {
        chatId = chatId1;
      } else if (chatSnapshot2.exists()) {
        chatId = chatId2;
      } else {
        chatId = chatId1;
      }

      // Corrected: Await the result of addInquiryData
      const inquiryId = await addInquiryData(chatId);

      const messageData = {
        senderId: userId,
        receiverId: formData.ownerId,
        message: newMessage,
        inquiryId: inquiryId,
        listingId: initialData.id,
        offerStatus: "pending",
        timestamp: serverTimestamp(),
      };

      if (userId === formData.ownerId) {
        alert("You cannot send a message to yourself");
        return;
      }

      if (chatSnapshot1.exists()) {
        const chatData = chatSnapshot1.val();
        const existingMessages =
          chatData && Array.isArray(chatData.messages) ? chatData.messages : [];

        console.log("🚀 Existing messages in chatSnapshot1:", existingMessages);
        console.log("🛠 Type of existingMessages:", typeof existingMessages);

        await update(chatRef1, {
          messages: [...existingMessages, messageData], // Ensure messages is always an array
        });
      } else if (chatSnapshot2.exists()) {
        const chatData = chatSnapshot2.val();
        const existingMessages =
          chatData && Array.isArray(chatData.messages) ? chatData.messages : [];

        console.log("🚀 Existing messages in chatSnapshot2:", existingMessages);
        console.log("🛠 Type of existingMessages:", typeof existingMessages);

        await update(chatRef2, {
          messages: [...existingMessages, messageData],
        });
      } else {
        console.log("🔥 No existing chat found, creating a new one.");
        await set(chatRef1, {
          senderId: userId,
          receiverId: formData.ownerId,
          messages: [messageData], // Start with an array
        });
      }

      const renterDataPromise = await getCurrentUserDetails();
      const listingDataPromise = await getListingByID(initialData.id);

      const [renterData, listingPromiseData] = await Promise.all([
        renterDataPromise,
        listingDataPromise,
      ]);

      const listingName = listingPromiseData.listingTitle;

      const bookingTitle =
        listingName + " - " + `${renterData.firstName} ${renterData.lastName}`;

      const database = getDatabase();
      const bookingRef = ref(database, `bookings`);

      const paymentRef = ref(database, `payments`);

      const userRef = ref(database, `users/${auth.currentUser.uid}`);

      const previousBookings = await get(userRef);
      let activeBookings;
      if (previousBookings.activeBookings) {
        activeBookings = previousBookings + 1;
        await update(userRef, { activeBookings });
      } else {
        activeBookings = 1;
        await update(userRef, { activeBookings });
      }

      // Use push to generate a new unique key
      const newBookingRef = push(bookingRef);

      // Get the key (ID) of the newly created node
      const newBookingId = newBookingRef.key;

      const newPaymentRef = push(paymentRef);
      console.log(newBookingRef, "testtestmasrppr");
      // Get the key (ID) of the newly created node
      const newPaymentId = newPaymentRef.key;
      console.log(newBookingId, "new booking");
      await set(newBookingRef, {
        listingId: initialData.id,
        renter: auth.currentUser.uid,
        owner: formData.ownerId,
        inquiryId: inquiryId,
        listingTitle: bookingTitle,
        priceOffered: 0,
        paymentId: newPaymentId,
        // totalWaivers: inquiryDetails.inquiryData.total,
        waiversFilled: {
          adult: 0,
          minor: 0,
        },
        timestamp: serverTimestamp(),
        status: "pending",
      });

      const ownerSubject = `New Inquiry Received`;
      const ownerEmail = ownerDetails.email;
      const renterName = userDetails.firstName + " " + userDetails.lastName;
      const ownerBodyData = await InquiryReceivedEmailBody(
        renterName,
        initialData.listingTitle
      );

      sendEmail(ownerEmail, ownerSubject, ownerBodyData);

      const ownerSmsBody = `You have received a booking inquiry.\n\nFollow the link to your dashboard to view your messages: https://www.bbrbassboatrentals.com/dashboard/inbox`;
      const ownerSmsResponse = sendSMS(ownerDetails.phone, ownerSmsBody);
      console.log(ownerSmsResponse);

      console.log("done");
      if (!newUser) {
        lastStep();
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  async function addInquiryData(chatId) {
    const database = getDatabase();
    const inquiryRef = ref(database, `inquiries`);
    console.log("Type of inquiryData:", typeof inquiryData);
    console.log("InquiryData:", inquiryData);
    console.log("Additionals Type:", typeof inquiryData.additionals);
    console.log("Additionals:", inquiryData.additionals);
    try {
      // Use push to generate a new unique key
      const newInquiryRef = push(inquiryRef);

      // Get the key (ID) of the newly created node
      const newInquiryId = newInquiryRef.key;

      await set(newInquiryRef, {
        listingId: initialData.id,
        renter: auth.currentUser.uid,
        owner: formData.ownerId,
        chatId: chatId,
        inquiryData,
        timestamp: serverTimestamp(),
        status: "pending",
      });

      // Corrected: Return the ID of the newly created node
      return newInquiryId;
    } catch (error) {
      console.error("Error adding inquiry:", error);
      throw error; // Re-throw the error to handle it elsewhere if needed
    }
  }

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  const Navigate = useNavigate();

  const [step, setStep] = useState(1);

  const auth = useAuth();

  const [formData, setFormData] = useState({
    listingTitle: "",
    shortName: "",
    capacity: "",
    description: "",
    ownerId: "",
    featured_image: null,
    location: "",
    make: "",
    model: "",
    year: "",
    length: "",
    engineNumber: "",
    engineHorsepower: "",
    engineMaker: "",
    engineModel: "",
  });

  const [message, setMessage] = useState("");

  const [error, setError] = useState();

  const splitFeatures = () => {
    const features = initialData.features || [];
    const midpoint = Math.ceil(features.length / 2);
    const firstHalf = features.slice(0, midpoint);
    const secondHalf = features.slice(midpoint);
    return { firstHalf, secondHalf };
  };

  const { firstHalf, secondHalf } = splitFeatures();

  const [isCreateOfferModalOpen, setCreateOfferModalOpen] = useState(false);

  const openCreateOfferModal = async () => {
    try {
      const userData = await getUserByID(auth.currentUser.uid);
      if (userData.status !== "Active") {
        const userName = userData.firstName + " " + userData.lastName;
        const adminEmailBody = await GetVerifiedForBassBoat(userName);
        sendEmail(userData?.email, "Bass Boat Verification", adminEmailBody);
      }
    } catch (error) {
      console.error("Error fetching user data", error);
    }

    if (auth.currentUser) {
      if (
        userDetails.status !== "Active" &&
        initialData.boatType === "Bass Boat"
      ) {
        setError(
          <span>
            You need to be verified to rent bass boats.
            <Button className="mt-2" href="/dashboard/get-verified">
              Get Verified
            </Button>
          </span>
        );
        setCreateOfferModalOpen(true);
      } else {
        setCreateOfferModalOpen(true);
        setError("");
      }
    }
  };

  const closeCreateOfferModal = () => {
    setCreateOfferModalOpen(false);
  };

  const calculateEstimatedPricing = () => {
    console.log(inquiryData, "inquiryData");
    if (
      inquiryData &&
      inquiryData.sameDayHours !== undefined &&
      inquiryData.overnight !== undefined
    ) {
      const inquiryHours = inquiryData.sameDayHours;
      const inquiryovernight = inquiryData.overnight;
      let pricing = "";

      // Determine base pricing based on inquiry hours
      if (inquiryHours === 8) {
        pricing = trailerCheckbox ? 500 : 400; // Assuming trailer adds $100 to the base price
      } else if (inquiryHours < 8) {
        const filterPriceFromApiByHour =
          initialData?.pricing?.hourlyOptions?.filter(
            ({ hours }) => parseInt(hours) === parseInt(inquiryHours)
          );

        const filterOneHourPrice = initialData?.pricing?.hourlyRate;

        const priceMultiplier =
          inquiryHours !== 0 && filterPriceFromApiByHour?.length < 1
            ? filterOneHourPrice
            : 0;

        pricing =
          filterPriceFromApiByHour?.length > 0 &&
          !isNaN(parseInt(filterPriceFromApiByHour[0]?.price))
            ? parseInt(filterPriceFromApiByHour[0]?.price) +
              (trailerCheckbox ? 100 : 0)
            : 0 +
              (trailerCheckbox ? 100 : 0) +
              parseInt(priceMultiplier) * inquiryHours; //* inquiryHours
      } else if (inquiryHours > 8 && inquiryHours < 24) {
        pricing = inquiryHours * hourlyRate - 400 + (trailerCheckbox ? 100 : 0);
      } else if (inquiryHours === 24) {
        pricing = trailerCheckbox ? 500 : 400;
      } else if (inquiryHours > 24) {
        pricing = (inquiryHours - 14) * hourlyRate - 500;
      }

      // if (
      //   initialData &&
      //   initialData.boatType != "Bass Boat" &&
      //   initialData?.pricing &&
      //   initialData?.pricing?.hourlyRate
      // ) {
      //   const hourlyOption = initialData.pricing?.hourlyOptions?.find(
      //     (option) => option.hours == inquiryHours
      //   );
      //   console.log(initialData.pricing.hourlyOptions);

      //   if (hourlyOption) {
      //     pricing = Number(hourlyOption.price) + (trailerCheckbox ? 100 : 0); // Use the price from dailyOptions if found
      //   } else {
      //     pricing = inquiryHours * hourlyRate + (trailerCheckbox ? 100 : 0);
      //   }
      // }

      // Apply overnight charges
      if (inquiryovernight >= 1 && inquiryovernight <= 6) {
        const basePrices = [400, 600, 700, 800, 900, 1000];
        pricing += basePrices[inquiryovernight - 1]; // Assuming overnight prices are additional
      } else if (inquiryovernight >= 7) {
        const lastPrice = 1100; // Update with the actual last price
        pricing += lastPrice + (inquiryovernight - 6) * 100;
      }

      if (
        initialData &&
        initialData?.boatType != "Bass Boat" &&
        initialData?.pricing &&
        initialData.pricing?.dailyOptions
      ) {
        const dailyOption = initialData.pricing?.dailyOptions?.find(
          (option) => option.days == inquiryovernight
        );

        if (dailyOption) {
          pricing = Number(dailyOption.price) + (trailerCheckbox ? 100 : 0); // Use the price from dailyOptions if found
        }
      }
      return pricing;
    }
    return ""; // Return empty string if inquiryData or its properties are not defined
  };

  const overnightText = () => {
    if (
      inquiryData &&
      inquiryData.overnight !== undefined &&
      (inquiryData.overnight > 0 || inquiryData.sameDayHours >= 24)
    ) {
      return "Including overnight trailer package.";
    }
    return "";
  };

  const [ownerDetails, setOwnerDetails] = useState([]);

  useEffect(() => {
    if (initialData) {
      setFormData((prevData) => ({
        ...prevData,
        listingTitle: initialData.listingTitle || "",
        shortName: initialData.shortName || "",
        capacity: initialData.capacity || "",
        description: initialData.description || "",
        ownerId: initialData.ownerId || "",
        featured_image: initialData.featured_image || null,
        location: initialData.storageAddress || "",
        make: initialData.make || "",
        model: initialData.model || "",
        year: initialData.year || "",
        length: initialData.length || "",
        engineNumber: initialData.engineNumber || "",
        engineHorsepower: initialData.engineHorsepower || "",
        engineMaker: initialData.engineMaker || "",
        engineModel: initialData.engineModel || "",
      }));
      console.log("initial data", initialData);
    }

    if (initialData.images) {
      setImages([...initialData.images, initialData.featured_image]);
    }

    if (initialData.ownerId) {
      getUserByID(initialData.ownerId)
        .then((userDetails) => {
          setOwnerDetails(userDetails);
          console.log("ownerDetails", userDetails);
        })
        .catch((error) => {
          console.error("Error fetching owner details:", error);
        });
    }

    console.log(images);
    getLocationName(initialData.storageAddress);
  }, [initialData]);

  const [images, setImages] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [inquiryData, setInquiryData] = useState({
    sameDayHours: 0,
    sameDayMinutes: 0,
    overnight: 0,
    prefferedDate: null,
    prefferedTime: null,
    adults: 0,
    seniors: 0,
    children: 0,
    infants: 0,
    total: 0,
    comments: "",
    additionals: {
      deliveryPickup: 0,
      trailer: 0,
      captain: 0,
    },
  });

  const [signUpData, setSignUpData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });

  const handleDateChange = (date, option) => {
    if (date == null) {
      // Handle the case where date is null
    } else {
      // Assuming option is a string indicating the option, like 'otherDateOption1'
      setInquiryData((prevData) => ({
        ...prevData,
        [option]: dayjs(date).format("dddd, MMMM D, YYYY"),
      }));
    }
  };

  const handleTimeChange = (time, option) => {
    if (time == null) {
    } else {
      // Assuming option is a string indicating the option, like 'otherDateOption1'
      setInquiryData((prevData) => ({
        ...prevData,
        [option]: dayjs(time).format("hh:mm:a"),
      }));
    }
    console.log(inquiryData);
  };

  const handleInquiryTextChange = (event, option) => {
    const text = event.target.value;

    if (text == null) {
    } else {
      // Assuming option is a string indicating the option, like 'otherDateOption1'
      setInquiryData((prevData) => ({
        ...prevData,
        [option]: text,
      }));
    }
    console.log(inquiryData);
  };

  const handleSignUpTextChange = (event, option) => {
    const text = event.target.value;

    if (text == null) {
    } else {
      setSignUpData((prevData) => ({
        ...prevData,
        [option]: text,
      }));

      console.log(signUpData);
    }
    console.log(signUpData);
  };

  const handleInquiryChange = (e, name, action) => {
    let updatedValue;

    setInquiryData((prevData) => ({
      ...prevData,
      estimatedPrice: `${
        deliveryPickupCheckbox
          ? parseInt(calculateEstimatedPricing() + 400)
          : calculateEstimatedPricing()
      }`,
    }));

    if (action === "add_circle") {
      updatedValue = inquiryData[name] + 1;
    } else if (action === "remove_circle") {
      updatedValue = Math.max(inquiryData[name] - 1, 0);
    } else {
      updatedValue = parseInt(e.target.value, 10) || 0;
    }

    // Check if 'overnight' is 0 before updating 'sameDayHours' and 'sameDayMinutes'
    if (name === "overnight" && updatedValue > 0) {
      setInquiryData((prevData) => ({
        ...prevData,
        overnight: updatedValue,
        sameDayHours: 0,
        sameDayMinutes: 0,
      }));

      setTrailerCheckbox(true);
    } else if (name === "sameDayHours" || name === "sameDayMinutes") {
      setInquiryData((prevData) => ({
        ...prevData,
        overnight: 0,
        [name]: updatedValue,
      }));
    } else {
      setInquiryData((prevData) => ({
        ...prevData,
        [name]: updatedValue,
      }));
    }

    if (name === "sameDayHours" && updatedValue >= 24) {
      setTrailerCheckbox(true);
    }
    console.log("change: " + inquiryData.sameDayHours);
  };

  const [hourlyRate, setHourlyRate] = useState(100);
  useEffect(() => {
    const platformPrices = async () => {
      try {
        const rateHourly = await getPlatformHourlyRate();
        setHourlyRate(rateHourly);
        if (initialData.pricing?.hourlyRate) {
          setHourlyRate(initialData.pricing?.hourlyRate);
        }
      } catch (error) {
        console.log(error);
      }
    };
    platformPrices();
  }, []);

  const [listingId, setListingId] = useState("");

  useEffect(() => {
    const total =
      inquiryData.adults +
      inquiryData.seniors +
      inquiryData.children +
      inquiryData.infants;
    if (total !== inquiryData.total) {
      setInquiryData((prevData) => ({ ...prevData, total: total }));
    }

    setListingId(initialData.id);
    console.log(listingId);

    const fetchEvents = async () => {
      try {
        setLoading(true);
        const individualEvents = await getEventsByListingId(listingId);
        const filteredEvents = individualEvents.filter(
          (event) => event.status != "cancelled"
        );
        setCalendarEvents(filteredEvents);

        const updatedReviews = await getReviewsByListingId(initialData.id);

        setListingReviews(updatedReviews);
      } catch (error) {
        console.error("Error fetching events:", error);
        setCalendarEvents([]);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [inquiryData, formData, initialData]);

  async function nextStep() {
    setStep((prevStep) => prevStep + 1);
  }

  const lastStep = () => {
    setStep(9);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  // const events = [
  //   {
  //     id: 1,
  //     title: 'Event 1',
  //     start: new Date(2024, 0, 10),
  //     end: new Date(2024, 0, 15),
  //   },
  //   // Add more events as needed
  // ];

  const [calendarEvents, setCalendarEvents] = useState([]);

  const StarRating = ({ rating, onRatingChange }) => {
    const stars = [1, 2, 3, 4, 5];

    return (
      <div>
        {stars.map((star) => (
          <span
            key={star}
            onClick={() => onRatingChange(star)}
            style={{
              cursor: "pointer",
              color: star <= rating ? "gold" : "gray",
              fontSize: "26px",
            }}
          >
            &#9733;
          </span>
        ))}
      </div>
    );
  };

  const [ReviewformData, setReviewFormData] = useState({
    firstname: "",
    lastname: "",
    review: "",
    listingAccuracy: 0,
    itineraryExperience: 0,
    vesselEquipment: 0,
    departureReturn: 0,
    value: 0,
    communication: 0,
  });

  const handleReviewInputChange = (e) => {
    const { name, value } = e.target;
    setReviewFormData({ ...ReviewformData, [name]: value });
  };

  const handleRatingChange = (category, rating) => {
    setReviewFormData({ ...ReviewformData, [category]: rating });
  };

  const initialFormData = {
    firstname: "",
    lastname: "",
    review: "",
    listingAccuracy: 0,
    itineraryExperience: 0,
    vesselEquipment: 0,
    departureReturn: 0,
    value: 0,
    communication: 0,
  };

  const resetReviewForm = () => {
    setReviewFormData({ ...initialFormData });
  };
  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    const database = getDatabase();
    const reviewsRef = ref(database, `reviews/${initialData.id}`);

    try {
      // Generate a dynamic ID for the new review node
      const newReviewRef = push(reviewsRef);
      const newReviewId = newReviewRef.key;

      // Create the review data object
      const reviewData = {
        firstname: ReviewformData.firstname,
        lastname: ReviewformData.lastname,
        review: ReviewformData.review,
        timestamp: serverTimestamp(),
      };

      // Include rating categories in the review data
      [
        "Listing Accuracy",
        "Itinerary & Experience",
        "Vessel & Equipment",
        "Departure & Return",
        "Value",
        "Communication",
      ].forEach((category) => {
        reviewData[category] = ReviewformData[category];
      });

      // Add the review data to the new review node
      await set(newReviewRef, reviewData);
      console.log("Review data added to Firebase successfully");

      const updatedReviews = await getReviewsByListingId(initialData.id);

      // Set the state with the updated reviews
      setListingReviews(updatedReviews);
      resetReviewForm();
    } catch (error) {
      console.error("Error adding review data to Firebase:", error);
      // Handle the error as needed
    }
  };

  const [listingLockDates, setListingLockDates] = useState([]);

  useEffect(() => {
    const fetchReviewDetails = async () => {
      try {
        const review = await getReviewsByListingId(initialData.id);
        setListingReviews(review);
        const userData = await getCurrentUserDetails();
        setUserDetails(userData);
      } catch (error) {
        console.error("Error fetching listing details:", error);
      }
    };
    fetchReviewDetails();
  }, []); // Empty dependency array means the effect runs once when the component mounts

  // const disabledDates = [
  //   '02/05/2024',
  //   '02/10/2024',
  // ];

  const formatDate = (date) => {
    const formattedDate = new Date(date);
    const mm = String(formattedDate.getMonth() + 1).padStart(2, "0");
    const dd = String(formattedDate.getDate()).padStart(2, "0");
    const yyyy = formattedDate.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  };

  const isDateDisabled = (date) => {
    try {
      const formattedDate = dayjs(date).format("MM/DD/YYYY");

      let disabledDatesFromCalendar = [];
      if (calendarEvents != null) {
        disabledDatesFromCalendar = calendarEvents.reduce((acc, event) => {
          const startFormatted = formatDate(event.start);
          const endFormatted = formatDate(event.end);

          // Add the start date
          if (!acc.includes(startFormatted)) {
            acc.push(startFormatted);
          }

          // Add the end date
          if (!acc.includes(endFormatted)) {
            acc.push(endFormatted);
          }

          // Add dates in between if the duration is more than one day
          if (event.start !== event.end) {
            const startDate = new Date(event.start);
            const endDate = new Date(event.end);

            const currentDate = new Date(startDate);

            while (currentDate < endDate) {
              currentDate.setDate(currentDate.getDate() + 1);
              const currentDateFormatted = formatDate(currentDate);

              if (!acc.includes(currentDateFormatted)) {
                acc.push(currentDateFormatted);
              }
            }
          }

          return acc;
        }, []);
      }
      // Extract disabledDates from listingLockDates
      let disabledDatesFromLockDates = [];
      if (initialData.lockDates) {
        console.log("initialData.lockDates");
        console.log(initialData.lockDates);
        disabledDatesFromLockDates = Object.values(
          initialData.lockDates
        ).reduce((acc, lockDate) => {
          const startDate = formatDate(lockDate.start_date);
          const endDate = formatDate(lockDate.end_date);

          // Add the start date
          if (!acc.includes(startDate)) {
            acc.push(startDate);
          }

          // Add the end date
          if (!acc.includes(endDate)) {
            acc.push(endDate);
          }

          // Add dates in between if the duration is more than one day
          const startDateObj = new Date(lockDate.start_date);
          const endDateObj = new Date(lockDate.end_date);

          const currentDate = new Date(startDateObj);

          while (currentDate < endDateObj) {
            currentDate.setDate(currentDate.getDate() + 1);
            const currentDateFormatted = formatDate(currentDate);

            if (!acc.includes(currentDateFormatted)) {
              acc.push(currentDateFormatted);
            }
          }

          return acc;
        }, []);
      }
      const allDisabledDates = [
        ...disabledDatesFromCalendar,
        ...disabledDatesFromLockDates,
      ];

      return allDisabledDates.includes(formattedDate);
    } catch (e) {
      // Handle the exception if needed
    }
  };

  useEffect(() => {
    const fetchListingDetails = async () => {
      try {
        const details = await getListingByID(initialData?.id);

        setListing(details);
        setIsCaptain(details?.boatCaptain);
      } catch (error) {
        console.error("Error fetching listing details:", error);
      }
    };

    fetchListingDetails();
  }, [initialData?.id]);

  return (
    <>
      <section className="p-0 cs-bg-dark" style={{ overflow: "hidden" }}>
        <Row>
          <Col>
            <ImageSlider images={images} />
          </Col>
        </Row>
      </section>

      <section className="pt-4 cs-bg-dark text-white">
        <Container>
          <Row className="mt-4 listing-container-mobile">
            <Col md={8}>
              <Row>
                <Col>
                  <h2>{initialData.listingTitle || "Boat Title"}</h2>
                  <h5>{initialData.boatType || ""}</h5>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex" style={{ gap: "20px" }}>
                  <p
                    className="d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <FaMapPin />
                    {listingLocation}
                  </p>
                </Col>
              </Row>
              <hr />
              {initialData?.pricing?.hourlyOptions && (
                <>
                  <Row>
                    <Col>
                      <h5>Hourly Pricing</h5>
                    </Col>
                  </Row>
                  <hr />
                  {initialData?.pricing?.hourlyOptions?.map((prices, i) => (
                    <Row key={i} className="mt-3">
                      <Col className="d-flex" style={{ gap: "20px" }}>
                        <p
                          className="d-flex align-items-center"
                          style={{ gap: "5px" }}
                        >
                          <span className="mx-3">
                            {prices?.hours}{" "}
                            {prices?.hours > 1 ? "hours" : "hour"}
                          </span>
                          <FaArrowRightLong />
                          <span className="mx-3">${prices?.price}</span>
                        </p>
                      </Col>
                    </Row>
                  ))}
                  <hr />
                </>
              )}
              {initialData?.pricing?.dailyOptions && (
                <>
                  <Row>
                    <Col>
                      <h5>Daily Pricing</h5>
                    </Col>
                  </Row>
                  <hr />
                  {initialData?.pricing?.dailyOptions?.map((prices, i) => (
                    <Row key={i} className="mt-3">
                      <Col className="d-flex" style={{ gap: "20px" }}>
                        <p
                          className="d-flex align-items-center"
                          style={{ gap: "5px" }}
                        >
                          <span className="mx-3">
                            {prices?.days} {prices?.days > 1 ? "days" : "day"}
                          </span>
                          <FaArrowRightLong />
                          <span className="mx-3">${prices?.price}</span>
                        </p>
                      </Col>
                    </Row>
                  ))}
                  <hr />
                </>
              )}

              <Row>
                <Col>
                  <p>{initialData.description || "Description here"}</p>
                </Col>
              </Row>
              {initialData?.boatCaptain !== undefined &&
                initialData?.boatCaptain !== null && (
                  <>
                    <hr />
                    <Row>
                      <Col>
                        <Row>
                          <Col xs={2} className="d-flex justify-content-center">
                            <TbSteeringWheel style={{ fontSize: "76px" }} />
                          </Col>
                          <Col
                            xs={10}
                            className="d-flex flex-column justify-content-center"
                            style={{ color: "#ddb467" }}
                          >
                            <h5 className="text-white">
                              {initialData?.boatCaptain
                                ? "Only Boat Owner can drive this boat"
                                : "Both Renters & Boat Owner are allowed to drive this boat"}
                            </h5>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              <hr />

              <Row>
                <Col>
                  <Row>
                    <Col xs={2} className="d-flex justify-content-center">
                      <FaPeopleGroup style={{ fontSize: "76px" }} />
                    </Col>
                    <Col
                      xs={10}
                      className="d-flex flex-column justify-content-center"
                    >
                      <h5>{initialData.capacity} Guests Capacity</h5>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col>
                  <h4>Features & Details</h4>
                  <h5>Boat Details</h5>
                  <Row className="px-2">
                    <Col xs={12} className="px-3">
                      <Row className="cs-bg-dark2 p-3">
                        <Col xs={2} className="text-center">
                          <CiShoppingTag
                            style={{ fontSize: "44px", textAlign: "center" }}
                          />
                        </Col>
                        <Col xs={2}>
                          <p className="mb-0 fs-10">Make</p>
                          <p className="mb-0">{initialData.make}</p>
                        </Col>
                        <Col xs={2}>
                          <p className="mb-0 fs-10">Model</p>
                          <p className="mb-0">{initialData.model}</p>
                        </Col>
                        {initialData.boatType === "Bass Boat" && (
                          <Col xs={2} className="text-center">
                            <CiCalendar
                              style={{ fontSize: "44px", textAlign: "center" }}
                            />
                          </Col>
                        )}
                        <Col
                          xs={2}
                          className="d-flex flex-column justify-content-center"
                        >
                          <p className="mb-0 fs-10">
                            {initialData.boatType === "Bass Boat"
                              ? "Year"
                              : "Motor Type"}
                          </p>
                          <h6 className="mb-0">{initialData.year}</h6>
                        </Col>
                        {initialData.boatType !== "Bass Boat" && (
                          <Col
                            xs={2}
                            className="d-flex flex-column justify-content-center"
                          >
                            <p className="mb-0 fs-10">Horse Power</p>
                            <h6 className="mb-0">{initialData.hin}</h6>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {initialData.boatType === "Bass Boat" && (
                <>
                  <Row className="mt-4">
                    <Col xs="6">
                      <ul>
                        {firstHalf.map((feature, index) => (
                          <li key={index}>{feature}</li>
                        ))}
                      </ul>
                    </Col>
                    <Col xs="6">
                      <ul>
                        {secondHalf.map((feature, index) => (
                          <li key={index}>{feature}</li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <h4>Additional Boat Info</h4>
                      <h5 className="mt-3">Motor Details</h5>
                      <Row className="px-2">
                        <Col xs={12} className="px-3">
                          <Row className="cs-bg-dark2 p-3">
                            <Col xs={2} className="text-center">
                              <CiShoppingTag
                                style={{
                                  fontSize: "44px",
                                  textAlign: "center",
                                }}
                              />
                            </Col>
                            <Col xs={3}>
                              <h6 className="mb-0">Make</h6>
                              <p className="mb-0">{initialData.motorMake}</p>
                            </Col>
                            <Col xs={3}>
                              <h6 className="mb-0">Model</h6>
                              <p className="mb-0">{initialData.motorModel}</p>
                            </Col>
                            <Col xs={2} className="text-center">
                              <RxWidth
                                style={{
                                  fontSize: "44px",
                                  textAlign: "center",
                                }}
                              />
                            </Col>
                            <Col
                              xs={2}
                              className="d-flex flex-column justify-content-center"
                            >
                              <p className="mb-0 fs-10">Year</p>
                              <h6 className="mb-0">{initialData.motorYear}</h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <h5 className="mt-3">Trailer Details</h5>
                      <Row className="px-2">
                        <Col xs={12} md={8} className="px-3">
                          <Row className="cs-bg-dark2 p-3">
                            <Col xs={2} className="text-center">
                              <CiShoppingTag
                                style={{
                                  fontSize: "44px",
                                  textAlign: "center",
                                }}
                              />
                            </Col>
                            <Col xs={5}>
                              <h6 className="mb-0">Make</h6>
                              <p className="mb-0">{initialData.trailerMake}</p>
                            </Col>
                            <Col xs={5}>
                              <h6 className="mb-0">Year</h6>
                              <p className="mb-0">{initialData.trailerYear}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <h5 className="mt-3">Trolling Motor Details</h5>
                      <Row className="px-2">
                        <Col xs={12} md={10} className="px-3">
                          <Row className="cs-bg-dark2 p-3">
                            <Col xs={2} className="text-center">
                              <CiShoppingTag
                                style={{
                                  fontSize: "44px",
                                  textAlign: "center",
                                }}
                              />
                            </Col>
                            <Col xs={3}>
                              <h6 className="mb-0">Make</h6>
                              <p className="mb-0">
                                {initialData.trollingMotorMake}
                              </p>
                            </Col>
                            <Col xs={4}>
                              <h6 className="mb-0">Model</h6>
                              <p className="mb-0">
                                {initialData.trollingMotorModel}
                              </p>
                            </Col>
                            <Col
                              xs={3}
                              className="d-flex flex-column justify-content-center"
                            >
                              <p className="mb-0 fs-10">Year</p>
                              <h6 className="mb-0">
                                {initialData.trailerYear}
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <h5 className="mt-3">Shallow Water Anchors</h5>
                      <Row className="px-2">
                        <Col xs={12} md={7} className="px-3">
                          <Row className="cs-bg-dark2 p-3">
                            <Col xs={2} className="text-center">
                              <CiShoppingTag
                                style={{
                                  fontSize: "44px",
                                  textAlign: "center",
                                }}
                              />
                            </Col>
                            <Col xs={3}>
                              <h6 className="mb-0">Number</h6>
                              <p className="mb-0">
                                {initialData.shallowWaterAnchors}
                              </p>
                            </Col>
                            <Col xs={6}>
                              <h6 className="mb-0">Brand & Model</h6>
                              <p className="mb-0">
                                {initialData.shallowWaterAnchorBrandModel}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <h5 className="mt-3">FFS - Forward Facing Sonar</h5>
                      <Row className="px-2">
                        <Col xs={12} className="px-3">
                          <Row className="cs-bg-dark2 p-3">
                            <Col xs={2} className="text-center">
                              <CiShoppingTag
                                style={{
                                  fontSize: "44px",
                                  textAlign: "center",
                                }}
                              />
                            </Col>
                            <Col xs={4}>
                              <h6 className="mb-0">Make</h6>
                              <p className="mb-0">{initialData.ffsMake}</p>
                            </Col>
                            <Col xs={3}>
                              <h6 className="mb-0">Model</h6>
                              <p className="mb-0">{initialData.ffsModel}</p>
                            </Col>
                            <Col xs={3}>
                              <h6 className="mb-0">Year</h6>
                              <p className="mb-0">{initialData.ffsYear}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <h5 className="mt-3">Graph (Make, Model & Year)</h5>
                      <Row className="px-2">
                        <Col xs={12} className="px-3">
                          <Row
                            className="cs-bg-dark2 p-3"
                            style={{ gap: "20px" }}
                          >
                            <Row>
                              <Col xs={6}>
                                <h6 className="mb-0">
                                  Graph 1 Make - Model - Year
                                </h6>
                                <p className="mb-0">
                                  {initialData.graph1Make} -{" "}
                                  {initialData.graph1Model} -{" "}
                                  {initialData.graph1Year}
                                </p>
                              </Col>
                              <Col xs={6}>
                                <h6 className="mb-0">
                                  Graph 2 Make - Model - Year
                                </h6>
                                <p className="mb-0">
                                  {initialData.graph2Make} -{" "}
                                  {initialData.graph2Model} -{" "}
                                  {initialData.graph2Year}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <h6 className="mb-0">
                                  Graph 3 Make - Model - Year
                                </h6>
                                <p className="mb-0">
                                  {initialData.graph3Make} -{" "}
                                  {initialData.graph3Model} -{" "}
                                  {initialData.graph3Year}
                                </p>
                              </Col>
                              <Col xs={6}>
                                <h6 className="mb-0">
                                  Graph 4 Make - Model - Year
                                </h6>
                                <p className="mb-0">
                                  {initialData.graph4Make} -{" "}
                                  {initialData.graph4Model} -{" "}
                                  {initialData.graph4Year}
                                </p>
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              <hr />
              <Row className="listingCal">
                <Col>
                  <h4>Check Availability</h4>
                  <TwoMonthCalendar events={loading ? "" : calendarEvents} />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h4>Reviews</h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      {listingReviews &&
                        listingReviews.map((review, index) => (
                          <>
                            <Row
                              key={index}
                              className="mb-3 cs-bxg-dark2 px-2 py-4"
                            >
                              <Col xs={11}>
                                <Row className="mb-3">
                                  <h5>
                                    {`${review.firstname} ${review.lastname}`}{" "}
                                    {review.timestamp && (
                                      <small>
                                        {new Date(
                                          review.timestamp
                                        ).toLocaleDateString("en-GB", {
                                          day: "numeric",
                                          month: "long",
                                          year: "numeric",
                                        })}
                                      </small>
                                    )}
                                  </h5>
                                  {Object.entries(review).map(
                                    ([category, rating]) =>
                                      category !== "firstname" &&
                                      category !== "lastname" &&
                                      category !== "timestamp" &&
                                      category !== "review" && (
                                        <Col xs={3} className="mb-3">
                                          <div key={category}>
                                            <p className="mb-1 testimonialMessage">
                                              {category}
                                            </p>
                                            <div className="d-flex align-items-center">
                                              {[...Array(rating)].map(
                                                (_, i) => (
                                                  <FaStar
                                                    style={{ color: "gold" }}
                                                    key={i}
                                                  />
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                  )}
                                </Row>
                                <Row>
                                  <Col>
                                    <p className="testimonialMessage">
                                      {review.review}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        ))}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mt-3 mb-4">
                    <Col>
                      <div>
                        <h4>Add A Review</h4>
                      </div>
                      <Form onSubmit={handleReviewSubmit}>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="firstname"
                                value={ReviewformData.firstname}
                                onChange={handleReviewInputChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="lastname"
                                value={ReviewformData.lastname}
                                onChange={handleReviewInputChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Form.Label>Review</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="review"
                            value={ReviewformData.review}
                            onChange={handleReviewInputChange}
                          />
                        </FormGroup>
                        {/* Star Ratings */}
                        <Row>
                          {[
                            "Listing Accuracy",
                            "Itinerary & Experience",
                            "Vessel & Equipment",
                            "Departure & Return",
                            "Value",
                            "Communication",
                          ].map((category) => (
                            <Col xs={6} md={4}>
                              <FormGroup key={category} className="mt-3">
                                <Form.Label className="mb-0">
                                  {category}
                                </Form.Label>
                                {/* Integrate the StarRating component here */}
                                <StarRating
                                  rating={ReviewformData[category]}
                                  onRatingChange={(rating) =>
                                    handleRatingChange(category, rating)
                                  }
                                />
                              </FormGroup>
                            </Col>
                          ))}
                        </Row>

                        <Button type="submit" className="mt-4">
                          Submit Review
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={4} xs={12} className="px-4">
              <Row className="card p-3 inqueryBox">
                <Col className="d-flex flex-column">
                  <h3>Interested?</h3>
                  <Link
                    to={
                      auth.currentUser != null
                        ? "#"
                        : `/auth?redirect_to=${listingId}`
                    }
                    className="btn send-inquiry btn-block btn-primary mt-2"
                    onClick={openCreateOfferModal}
                  >
                    <i className="material-icons">mail</i>Book Now
                  </Link>
                  {error && (
                    <Alert className="mt-3" severity="error">
                      {error}
                    </Alert>
                  )}
                  {initialData &&
                    // initialData?.boatType !== "Bass Boat" &&
                    initialData?.pricing &&
                    initialData?.pricing?.hourlyRate && (
                      <Row className="mt-4">
                        <Col
                          xs={6}
                          className="d-flex flex-row align-items-center gap-2"
                        >
                          <h5>Starting From:</h5>
                        </Col>
                        <Col
                          xs={6}
                          className="d-flex flex-row align-items-center gap-2"
                        >
                          <h5>${initialData?.pricing?.hourlyRate}/hour</h5>
                        </Col>
                      </Row>
                    )}

                  <Row className="mt-4">
                    <Col
                      xs={6}
                      className="d-flex flex-row align-items-center gap-2"
                    >
                      <h5>Owner</h5>
                    </Col>
                    <Col
                      xs={6}
                      className="d-flex flex-row align-items-center gap-2"
                    >
                      <Image
                        src={ownerDetails.avatar}
                        roundedCircle
                        width={40}
                        onClick={() => setLightboxIsOpen(true)}
                        style={{ cursor: "pointer" }}
                      />
                      {lightboxIsOpen && (
                        <Lightbox
                          image={ownerDetails.avatar}
                          onClose={() => setLightboxIsOpen(false)}
                        />
                      )}
                      <h5>
                        {ownerDetails.firstName} {ownerDetails.lastName}
                      </h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <Dialog
        fullScreen={fullScreen}
        aria-labelledby="responsive-dialog-title"
        onClose={closeCreateOfferModal}
        open={isCreateOfferModalOpen}
      >
        <div className="d-flex justify-content-end">
          <IoClose
            className="close-icon"
            style={{
              color: "red",
              marginRight: "1rem",
              marginTop: "1rem",
              width: "max-content",
              fontSize: "1.5rem",
              textDecoration: "none",
            }}
            onClick={closeCreateOfferModal}
          />
        </div>
        {step === 1 && (
          <DialogTitle id="responsive-dialog-title">
            Send a Booking Inquiry
          </DialogTitle>
        )}
        {step === 2 && (
          <DialogTitle id="responsive-dialog-title">Duration</DialogTitle>
        )}
        {step === 3 && (
          <DialogTitle id="responsive-dialog-title">Preferred Date</DialogTitle>
        )}
        {step === 4 && (
          <DialogTitle id="responsive-dialog-title">Departure Time</DialogTitle>
        )}
        {step === 5 && (
          <DialogTitle id="responsive-dialog-title">Group Size</DialogTitle>
        )}
        {step === 6 && (
          <DialogTitle id="responsive-dialog-title">Anything Else?</DialogTitle>
        )}
        {step === 7 && (
          <DialogTitle id="responsive-dialog-title">
            Your Contact Details
          </DialogTitle>
        )}
        {step === 8 && (
          <DialogTitle id="responsive-dialog-title">Password</DialogTitle>
        )}
        {step === 9 && (
          <DialogTitle id="responsive-dialog-title">Inquiry Sent!</DialogTitle>
        )}
        <DialogContent>
          {auth.currentUser != null ? (
            <ProgressBar now={(step / 7) * 100} className="mb-4" />
          ) : (
            <ProgressBar now={(step / 9) * 100} className="mb-4" />
          )}

          {step === 1 && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <img
                  className="img-responsive"
                  src="/inquiry.png"
                  style={{ width: "120px", textAlign: "center" }}
                />
                <p>
                  We’ll ask you a few questions so the listing owner can send
                  you a custom price, itinerary and more information. No
                  commitment or credit card needed.
                </p>
              </Row>
            </>
          )}
          {step === 2 && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <h6 style={{ textAlign: "center" }}>
                  How long do you want your trip or rental to be?
                </h6>
                <h5 style={{ textAlign: "center" }}>Return on same day?</h5>

                {initialData &&
                  initialData?.pricing &&
                  initialData?.pricing?.hourlyOptions && (
                    <>
                      <Form.Label className="inquiry-label">
                        Hourly Packages
                      </Form.Label>
                      <div className="d-flex justify-content-center align-items-center gap-3">
                        <Form.Select
                          name="sameDayHours"
                          id="sameDayHours"
                          value={inquiryData.sameDayHours}
                          onChange={(e) => {
                            // setInquiryData((prevData) => ({
                            //   ...prevData,
                            //   overnight: 0,
                            //   sameDayHours:
                            //     // inquiryData.overnight === 0
                            //     parseInt(e.target.value, 10),
                            //   // : 0,
                            // }));
                            handleInquiryChange(e, "sameDayHours");
                          }}
                          className="form-select"
                          style={{ width: "90%" }}
                          required
                        >
                          <option value="" default>
                            Select An Option
                          </option>
                          {initialData?.pricing?.hourlyOptions?.map(
                            (option) => (
                              <option key={option.hours} value={option.hours}>
                                {option.hours} Hours (${option.price})
                              </option>
                            )
                          )}
                        </Form.Select>
                      </div>
                    </>
                  )}
                <Row>
                  <Col xs={12} className="text-center">
                    {((initialData?.boatType !== "Bass Boat" &&
                      initialData?.pricing?.hourlyRate) ||
                      initialData?.boatType == "Bass Boat") && (
                      <>
                        <Row>
                          <Col>
                            <Form.Label className="inquiry-label">
                              Rent By Hours
                            </Form.Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="d-flex" style={{ gap: "5px" }}>
                            <Button
                              onClick={() => handleRemove("sameDayHours")}
                              className="d-flex justify-content-center align-items-center"
                              style={{ width: "10px" }}
                            >
                              <i className="material-icons add-remove">
                                remove_circle
                              </i>
                            </Button>
                            <Form.Control
                              type="number"
                              name="sameDayHours"
                              className="form-control"
                              value={inquiryData.sameDayHours}
                              onChange={(e) =>
                                handleInquiryChange(e, "sameDayHours")
                              }
                              required
                            />
                            <Button
                              onClick={() => handleAdd("sameDayHours")}
                              className="d-flex justify-content-center align-items-center"
                              style={{ width: "10px" }}
                            >
                              <i className="material-icons add-remove">
                                add_circle
                              </i>
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
                {inquiryData.sameDayHours > 0 &&
                  initialData.boatType === "Bass Boat" && (
                    <Alert style={{ width: "90%" }} severity="warning">
                      Additonal motor hours cost $50 per hour.
                    </Alert>
                  )}
                {initialData &&
                  (initialData.boatType === "Bass Boat" ||
                    (initialData.boatType !== "Bass Boat" &&
                      initialData.pricing &&
                      initialData.pricing.overnightEnabled) ||
                    (initialData.boatType !== "Bass Boat" &&
                      !initialData.pricing)) && (
                    <>
                      <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                        Overnight
                      </h5>
                      <div className="d-flex justify-content-center align-items-center gap-3">
                        <Form.Select
                          name="overnight"
                          value={inquiryData.overnight}
                          onChange={(e) => handleInquiryChange(e, "overnight")}
                          className="form-select"
                          style={{ width: "90%" }}
                          required
                        >
                          <option value="" default>
                            Select An Option
                          </option>
                          {initialData.pricing &&
                          // initialData.boatType !== "Bass Boat" &&
                          initialData.pricing.dailyOptions &&
                          initialData.pricing.dailyOptions.length > 0
                            ? initialData.pricing.dailyOptions.map((option) => (
                                <option key={option.days} value={option.days}>
                                  {`${option.days} Days (${
                                    option.days * 24
                                  } hours)`}
                                </option>
                              ))
                            : initialData.boatType === "Bass Boat" && (
                                <>
                                  <option value="1">1 Day (24 hours)</option>
                                  <option value="2">2 Days (48 hours)</option>
                                  <option value="3">3 Days (72 hours)</option>
                                  <option value="4">4 Days (96 hours)</option>
                                  <option value="5">5 Days (120 hours)</option>
                                  <option value="6">6 Days (144 hours)</option>
                                </>
                              )}
                        </Form.Select>
                      </div>
                    </>
                  )}
              </Row>
              <Row className="mt-4">
                <h5 style={{ textAlign: "center" }}>Additionals</h5>
                <FormGroup className="d-flex justify-content-center align-items-center">
                  {/*<div className="d-flex additional-box">
                     <FormControlLabel
                      style={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          checked={deliveryPickupCheckbox}
                          onChange={handleDeliveryPickupCheckboxChange}
                        />
                      }
                    />
                    <div>
                      <p style={{ fontWeight: "500", lineHeight: "28px" }}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            borderRadius: "8px",
                            background: "#1a1a1a",
                            color: "white",
                            marginRight: "5px",
                            padding: "4px 8px",
                          }}
                        >
                          $400
                        </span>
                        Check this box for delivery and pickup to 130 miles.
                      </p>
                      <p style={{ fontStyle: "italic", marginTop: "-11px" }}>
                        An additional $2 per mile will be added for trips over
                        130 miles.
                      </p>
                    </div>
                  </div> */}

                  {initialData &&
                    (initialData.boatType === "Bass Boat" ||
                      (initialData.boatType !== "Bass Boat" &&
                        initialData.pricing &&
                        initialData.pricing.overnightEnabled)) && (
                      <div className="d-flex mt-1 additional-box">
                        <FormControlLabel
                          style={{ alignItems: "flex-start" }}
                          control={
                            <Checkbox
                              disabled={
                                inquiryData.overnight > 0 ||
                                inquiryData.sameDayHours >= 24
                              }
                              checked={trailerCheckbox}
                              onChange={handleTrailerCheckbox}
                            />
                          }
                        />
                        <div>
                          <p style={{ fontWeight: "500", lineHeight: "28px" }}>
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                borderRadius: "8px",
                                background: "#1a1a1a",
                                color: "white",
                                marginRight: "5px",
                                padding: "4px 8px",
                              }}
                            >
                              $100
                            </span>
                            Trailer Package.
                            {inquiryData && inquiryData.overnight > 0 && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </p>
                          <p style={{ fontStyle: "italic", marginTop: "11px" }}>
                            Required for overnight rentals.
                          </p>
                        </div>
                      </div>
                    )}
                  <div className="d-flex mt-1 additional-box">
                    <FormControlLabel
                      style={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          checked={isCaptain || captainCheckbox}
                          onChange={handleCaptainCheckbox}
                          disabled={isCaptain}
                        />
                      }
                    />
                    <div>
                      <p style={{ fontWeight: "500", lineHeight: "28px" }}>
                        <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            borderRadius: "8px",
                            background: "#1a1a1a",
                            color: "white",
                            marginRight: "5px",
                            padding: "4px 8px",
                          }}
                        >
                          Quote
                        </span>
                        Optional Captain
                      </p>
                      <p style={{ fontStyle: "italic", marginTop: "11px" }}>
                        Additional charge by owner.
                      </p>
                    </div>
                  </div>
                </FormGroup>
              </Row>
              <Col xs={12} className="text-center mt-3">
                <Row>
                  <Col>
                    {console.log(
                      "calculateEstimatedPricing()",
                      calculateEstimatedPricing()
                    )}
                    <Form.Label className="inquiry-label">
                      Estimated Price:{" "}
                      {calculateEstimatedPricing() !== ""
                        ? `${
                            deliveryPickupCheckbox
                              ? "$" +
                                parseInt(calculateEstimatedPricing() + 400)
                              : "$" + calculateEstimatedPricing()
                          }`
                        : ""}
                      <br />
                      {overnightText()}
                    </Form.Label>
                    <Form.Control
                      type="hidden"
                      name="arivingdatetime"
                      className="form-control"
                      required
                    />
                  </Col>
                </Row>
              </Col>
            </>
          )}
          {step === 3 && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <h6 style={{ textAlign: "center" }}>
                  Provide the preferred date for your trip in the first field
                  below.
                </h6>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minDate={dayjs().startOf("day")} // Ensures that only today and future dates can be selected
                      value={dayjs(inquiryData.prefferedDate)}
                      onChange={(date) =>
                        handleDateChange(date, "prefferedDate")
                      }
                      label="Preferred Date"
                      required
                      shouldDisableDate={(date) =>
                        date.isBefore(dayjs(), "day")
                      } // Disables previous days
                    />
                  </LocalizationProvider>
                  <div className="d-flex flex-column gap-0">
                    <p className="p-tag-inquiry">
                      Duration:{" "}
                      <span style={{ fontWeight: "bold", display: "block" }}>
                        {inquiryData.overnight > 0
                          ? inquiryData.overnight + " over nights"
                          : inquiryData.sameDayHours + " Hours"}{" "}
                      </span>
                    </p>
                    <p className="p-tag-inquiry">
                      Pickup Date:{" "}
                      <span style={{ fontWeight: "bold", display: "block" }}>
                        {inquiryData.prefferedDate == null
                          ? ""
                          : inquiryData.prefferedDate}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                {/* 
                  <h5 style={{textAlign: 'center'}}>Other options</h5>
        <h6 style={{textAlign: 'center'}}>Provide additional dates if your dates are flexible and
you have other possible options.
          </h6>
          <div className="d-flex justify-content-center align-items-center gap-3">
                    <LocalizationProvider dateAdapter={AdapterDayjs}> 
                      <DatePicker 
                        value={inquiryData.otherDateOption1 ? dayjs(inquiryData.otherDateOption1) : null} 
                        onChange={(date) => handleDateChange(date, 'otherDateOption1')}
                        slotProps={{
                          field: { clearable: true, onClear: () => setCleared(true) },
                        }}
                        label="Other Option" 
                        shouldDisableDate={isDateDisabled}
                      />
                      </LocalizationProvider>
                      <div className='d-flex flex-column gap-0'>
                      <p className='p-tag-inquiry'>Duration: {inquiryData.otherDateOption1 == null ? '' : <span style={{fontWeight: "bold", display: "block"}}>{inquiryData.overnight > 0 ? inquiryData.overnight + ' over nights' : inquiryData.sameDayHours + ' Hours'} </span>}</p>
                      <p className='p-tag-inquiry'>Return: <span style={{fontWeight: "bold", display: "block"}}>{inquiryData.otherDateOption1 == null ?  '' : inquiryData.otherDateOption1}</span></p>
                      </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}> 
                      <DatePicker 
                        value={inquiryData.otherDateOption2 ? dayjs(inquiryData.otherDateOption2) : null} 
                        onChange={(date) => handleDateChange(date, 'otherDateOption2')}
                        slotProps={{
                          field: { clearable: true, onClear: () => setCleared(true) },
                        }}
                        label="Other Option"
                        shouldDisableDate={isDateDisabled} 
                      />
                      </LocalizationProvider>
                      <div className='d-flex flex-column gap-0'>
                      <p className='p-tag-inquiry'>Duration: {inquiryData.otherDateOption2 == null ? '' : <span style={{fontWeight: "bold", display: "block"}}>{inquiryData.overnight > 0 ? inquiryData.overnight + ' over nights' : inquiryData.sameDayHours + ' Hours'} </span>}</p>
                      <p className='p-tag-inquiry'>Return: <span style={{fontWeight: "bold", display: "block"}}>{inquiryData.otherDateOption2 == null ?  '' : inquiryData.otherDateOption2}</span></p>
                      </div>
                  </div> */}
              </Row>
            </>
          )}
          {step === 4 && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <h6 style={{ textAlign: "center" }}>
                  What time would you like to depart? You can skip this step if
                  you prefer the owner to suggest a time.
                </h6>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopTimePicker
                      value={dayjs(inquiryData.prefferedTime)}
                      onChange={(time) =>
                        handleTimeChange(time, "prefferedTime")
                      }
                      label="Preffered Time"
                    />
                  </LocalizationProvider>
                </div>
              </Row>
            </>
          )}
          {step === 5 && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <Form.Label className="inquiry-label">
                  Adults <small>(18 and older)</small>
                </Form.Label>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30px" }}
                    onClick={(e) =>
                      handleInquiryChange(e, "adults", "remove_circle")
                    }
                  >
                    <i className="material-icons add-remove">remove_circle</i>
                  </Button>
                  <Form.Control
                    type="number"
                    name="adults"
                    value={inquiryData.adults}
                    onChange={(e) => handleInquiryChange(e, "adults")}
                    className="form-control"
                    style={{ width: "120px" }}
                    required
                  />
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30px" }}
                    onClick={(e) =>
                      handleInquiryChange(e, "adults", "add_circle")
                    }
                  >
                    <i className="material-icons add-remove">add_circle</i>
                  </Button>
                </div>
                <Form.Label className="inquiry-label">
                  Seniors <small>(65 and older)</small>
                </Form.Label>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30px" }}
                    onClick={(e) =>
                      handleInquiryChange(e, "seniors", "remove_circle")
                    }
                  >
                    <i className="material-icons add-remove">remove_circle</i>
                  </Button>
                  <Form.Control
                    type="number"
                    name="seniors"
                    value={inquiryData.seniors}
                    onChange={handleInquiryChange}
                    className="form-control"
                    style={{ width: "120px" }}
                    required
                  />
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30px" }}
                    onClick={(e) =>
                      handleInquiryChange(e, "seniors", "add_circle")
                    }
                  >
                    <i className="material-icons add-remove">add_circle</i>
                  </Button>
                </div>
                <Form.Label className="inquiry-label">
                  Minors <small>(Younger than 18)</small>
                </Form.Label>

                <div className="d-flex justify-content-center align-items-center gap-3">
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30px" }}
                    onClick={(e) =>
                      handleInquiryChange(e, "children", "remove_circle")
                    }
                  >
                    <i className="material-icons add-remove">remove_circle</i>
                  </Button>
                  <Form.Control
                    type="number"
                    name="children"
                    value={inquiryData.children}
                    onChange={(e) => handleInquiryChange(e, "children")}
                    className="form-control"
                    style={{ width: "120px" }}
                    required
                  />
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30px" }}
                    onClick={(e) =>
                      handleInquiryChange(e, "children", "add_circle")
                    }
                  >
                    <i className="material-icons add-remove">add_circle</i>
                  </Button>
                </div>
                <Form.Label className="inquiry-label">
                  Infants <small>(Younger than 1 year old)</small>
                </Form.Label>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30px" }}
                    onClick={(e) =>
                      handleInquiryChange(e, "infants", "remove_circle")
                    }
                  >
                    <i className="material-icons add-remove">remove_circle</i>
                  </Button>
                  <Form.Control
                    type="number"
                    name="infants"
                    value={inquiryData.infants}
                    onChange={(e) => handleInquiryChange(e, "infants")}
                    className="form-control"
                    style={{ width: "120px" }}
                    required
                  />
                  <Button
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "30px" }}
                    onClick={(e) =>
                      handleInquiryChange(e, "infants", "add_circle")
                    }
                  >
                    <i className="material-icons add-remove">add_circle</i>
                  </Button>
                </div>
                <h5
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    borderTop: "1px dashed #a3aeb2",
                    padding: "13px 0 0",
                    width: "50%",
                  }}
                >
                  Total: {inquiryData.total}
                </h5>
              </Row>
            </>
          )}
          {step === 6 && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <h6 style={{ textAlign: "center" }}>
                  Do you have any specific needs or requests that the owner
                  needs to know?
                </h6>
                <TextField
                  style={{ width: "90%" }}
                  multiline
                  rows={4}
                  id="outlined-basic"
                  value={inquiryData.comments}
                  onChange={(text) => handleInquiryTextChange(text, "comments")}
                  label="Comments (Optional)"
                  variant="outlined"
                />

                <div className="d-flex mt-1 additional-box">
                  <FormControlLabel
                    style={{ alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        checked={setAcknowledgement1}
                        onChange={() =>
                          handleAcknowledgement("acknowledgement1")
                        }
                      />
                    }
                  />
                  <div>
                    <p style={{ fontWeight: "500", lineHeight: "28px" }}>
                      I acknowledge that the booking is subject to verification
                      and could be cancelled if its not approved.
                      <span style={{ color: "red" }}>*</span>
                      <br />
                      <small>Applicable only for Bass Boats</small>
                    </p>
                  </div>
                </div>

                <div className="d-flex mt-1 additional-box">
                  <FormControlLabel
                    style={{ alignItems: "flex-start" }}
                    control={
                      <Checkbox
                        checked={acknowledgement2}
                        onChange={() =>
                          handleAcknowledgement("acknowledgement2")
                        }
                      />
                    }
                  />
                  <div>
                    <p style={{ fontWeight: "500", lineHeight: "28px" }}>
                      Please check this box to acknowledge that you have read
                      and agreed to the cancellation policy - refer to the{" "}
                      <Link to="/">website</Link> for additional canecellation
                      information <span style={{ color: "red" }}>*</span>
                    </p>
                  </div>
                </div>
              </Row>
            </>
          )}
          {step === 7 && auth.currentUser === null && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <h6 style={{ textAlign: "center" }}>
                  Have an account with BBR Rentals?{" "}
                  <Link to="/auth">Sign In.</Link>
                </h6>
                <div className="d-flex gap-3 align-items-center justify-content-center flex-wrap">
                  <TextField
                    required
                    style={{ width: "45%" }}
                    value={signUpData.firstName}
                    onChange={(text) =>
                      handleSignUpTextChange(text, "firstName")
                    }
                    id="outlined-required"
                    label="First Name"
                  />
                  <TextField
                    required
                    style={{ width: "45%" }}
                    value={signUpData.lastName}
                    onChange={(text) =>
                      handleSignUpTextChange(text, "lastName")
                    }
                    id="outlined-required"
                    label="Last Name"
                  />
                  <TextField
                    required
                    style={{ width: "calc(90% + 1rem)" }}
                    id="outlined-required"
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    InputProps={{
                      pattern:
                        "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}",
                    }}
                    helperText={
                      !validEmail ? "Enter a valid email address" : ""
                    }
                  />
                  <MuiTelInput
                    style={{ width: "calc(90% + 1rem)" }}
                    helperText={
                      !validPhone ? "Enter a valid phone number" : null
                    }
                    defaultCountry="US"
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                </div>
              </Row>
            </>
          )}

          {step === 8 && auth.currentUser === null && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <h6 style={{ textAlign: "center" }}>
                  This will create an account with Getmyboat so you can read and
                  respond to messages from owners. Passwords must be at least 9
                  characters.
                </h6>
                <h6 style={{ textAlign: "center" }}>
                  Have an account with Get myboat?{" "}
                  <Link to="/auth">Sign In.</Link>
                </h6>
                <div className="d-flex gap-3 align-items-center justify-content-center flex-wrap">
                  <TextField
                    style={{ width: "calc(90% + 1rem)" }}
                    id="outlined-password-input"
                    value={signUpData.password}
                    onChange={(text) =>
                      handleSignUpTextChange(text, "password")
                    }
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                  />
                  <TextField
                    style={{ width: "calc(90% + 1rem)" }}
                    id="outlined-confirm-password-input"
                    label="Re-enter Password"
                    type="password"
                    autoComplete="current-password"
                  />
                  {/* <Button variant="btn btn-primary" style={{width: '90%'}} onClick={nextStep}>
                Create Account
              </Button> */}
                </div>
              </Row>
            </>
          )}

          {step === 9 && (
            <>
              <Row className="d-flex justify-content-center align-items-center gap-4">
                <img
                  className="img-responsive mt-3 mb-3"
                  src="/inquiry-sent.png"
                  style={{ width: "120px", textAlign: "center" }}
                />
                <h5 style={{ textAlign: "center", lineHeight: "27px" }}>
                  We’ll contact you with options for your <br />
                  trip soon.
                </h5>
                <div className="d-flex gap-3 mt-3 align-items-center justify-content-center flex-wrap">
                  <Button
                    variant="btn btn-primary mt-3 p-2"
                    style={{ width: "90%" }}
                    onClick={() => Navigate("/dashboard/inbox")}
                  >
                    Done
                  </Button>
                </div>
              </Row>
            </>
          )}
        </DialogContent>

        <DialogActions className="booking-modal-btns-mobile">
          {step === 1 && (
            <>
              <Button variant="btn btn-primary" onClick={nextStep}>
                Next
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
              <Button variant="btn btn-primary" onClick={nextStep}>
                Next
              </Button>
            </>
          )}
          {step === 3 && (
            <>
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
              <Button variant="btn btn-primary" onClick={nextStep}>
                Next
              </Button>
            </>
          )}
          {step === 4 && (
            <>
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
              <Button variant="btn btn-primary" onClick={nextStep}>
                Next
              </Button>
            </>
          )}
          {step === 5 && (
            <>
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
              <Button variant="btn btn-primary" onClick={nextStep}>
                Next
              </Button>
            </>
          )}
          {step === 6 && (
            <>
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
              <Button
                variant="btn btn-primary"
                className="send-inquiry"
                onClick={() => {
                  if (auth.currentUser != null) {
                    sendInquiry(false, auth.currentUser.uid);
                  } else {
                    nextStep();
                  }
                }}
              >
                {auth.currentUser != null ? "Submit" : "Next"}
              </Button>
              {error && <Alert severity="error">{error}</Alert>}
            </>
          )}
          {step === 7 && (
            <>
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
              <Button variant="btn btn-primary" onClick={nextStep}>
                Next
              </Button>
            </>
          )}
          {step === 8 && (
            <>
              <Button variant="secondary" onClick={prevStep}>
                Back
              </Button>
              <Button
                variant="btn btn-primary"
                className="send-inquiry"
                onClick={handleSubmit}
              >
                Submit
              </Button>
              {error && <Alert severity="error">{error}</Alert>}
              {/* <button type="button" className="btn btn-primary mt-2" onClick={sendInquiry}>
                <i className="material-icons">mail</i> Send Inquiry
              </button> */}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleListingLayout;
