import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import {
  getActiveListings,
  getAllAttributes,
  getEventsByID,
  getEventsByListingId,
  getAllBoatTypes,
} from "../utils";
import DateRangePicker from "rsuite/DateRangePicker";
import { InputGroup } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useLocation } from "react-router-dom";
import { addDays } from "date-fns";
import GoogleMaps from "../GoogleMaps";
import { MultiSelect } from "react-multi-select-component";
import { Skeleton } from "@mui/material";
import "rsuite/dist/rsuite.min.css";

import { CiCalendarDate } from "react-icons/ci";
import { CiGlobe } from "react-icons/ci";
import { PiBoatLight } from "react-icons/pi";
import { BsListStars } from "react-icons/bs";
import { GrPowerReset } from "react-icons/gr";

export default function Boats() {
  const [listings, setListings] = useState([]);
  const [boatFilters, setBoatFilters] = useState([]);
  const [boatTypesFilters, setBoatTypesFilters] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedBoatTypes, setSelectedBoatTypes] = useState([]);
  const [locationNames, setLocationNames] = useState({});
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [boatLocations, setBoatLocations] = useState([]);
  const [searchAddress, setSearchAddress] = useState("");
  const [grabSearchLocation, setGrabSearchLocation] = useState("");
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [searchedLocationGeoData, setSearchedGeoDataLocation] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [listingsPerPage] = useState(9);
  const [selectedBoatTypeOptions, setselectedBoatTypeOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [boatTypeOptions, setBoatTypeOptions] = useState([]);
  const [selectedFiltersOptions, setSelectedFiltersOptions] = useState([]);
  const [grabSelectedFilterOptions, setGrabSelectedFilterOptions] = useState(
    []
  );
  const [filtersOptions, setFilterOptions] = useState([]);
  const [isBoatsLoading, setIsBoatsLoading] = useState(true);

  const location = useLocation();

  const handleDateRangeChange = (value) => {
    if (value == null) {
      setSelectedDateRange([]);
    } else {
      setSelectedDateRange(value);
    }
  };

  const handleFilterByDateRange = () => {
    setDateRange(selectedDateRange);
  };

  const today = new Date();
  const tomorrow = addDays(new Date(), 1);
  const yesterday = addDays(new Date(), -1);
  const calculateDistance = (locationA, locationB) => {
    if (!locationA || !locationB) return;

    const lat1 = locationA.lat;
    const lon1 = locationA.lng;
    const lat2 = locationB.lat;
    const lon2 = locationB.lng;

    // Calculate distance using Haversine formula
    const R = 6371 * 1000;
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  };

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const listings = await getActiveListings();
        const allAttributes = await getAllAttributes();
        const allBoatTypes = await getAllBoatTypes();
        const optionsArray = Object.values(allAttributes[2]);

        const listingEventsPromises = listings.map(async (listing) => {
          const boatAddress = await getLocationName(
            listing?.storageAddress?.lat,
            listing?.storageAddress?.lng
          );
          const boatGeoData = await geocodeByAddress(boatAddress);
          const listingEvents = await getEventsByListingId(listing.id);
          const latLng = await getLatLng(boatGeoData[0]);

          const newLocationObject = {
            name: listing?.listingTitle,
            lat: latLng?.lat,
            lng: latLng?.lng,
            link: "listing/" + listing?.id,
            boatType: listing?.boatType,
            icon: "https://bbrbassboatrentals.com/static/media/loading.33ed2d822776ba990a07.png",
          };

          console.log("listings", listings);

          setBoatLocations((prevLocations) => [
            ...prevLocations,
            newLocationObject,
          ]);

          return {
            ...listing,
            listingEvents: listingEvents,
            features: listing?.features || [],
            listingAddress: latLng || [],
            listingGeoData: boatGeoData || [],
          };
        });

        const updatedListings = await Promise.all(listingEventsPromises);

        setListings(updatedListings);

        const boatTypeOptions = allBoatTypes?.map((boatType) => ({
          label: boatType,
          value: boatType,
        }));
        const newFiltersOptions = optionsArray?.map((option) => ({
          label: option,
          value: option,
        }));

        const filteredBoatTypes = boatTypeOptions?.sort((a, b) =>
          a.label.localeCompare(b.label)
        );

        setFilterOptions((prevOptions) => [...newFiltersOptions]);
        setBoatTypeOptions((prevOptions) => [...filteredBoatTypes]);

        setBoatTypesFilters(allBoatTypes);
        setBoatFilters(optionsArray);

        // Fetch location names for each listing
        const namesPromises = updatedListings?.map((listing) =>
          getLocationName(
            listing?.storageAddress?.lat,
            listing?.storageAddress?.lng
          )
        );
        const names = await Promise?.all(namesPromises);
        const locationNamesMap = {};
        updatedListings?.forEach((listing, index) => {
          locationNamesMap[listing.id] = names[index];
        });
        setLocationNames(locationNamesMap);
        setIsBoatsLoading(false);
      } catch (error) {
        console.error("Error fetching listings:", error);
      }
    };
    fetchListings();

    return () => {
      setIsBoatsLoading(true);
    };
  }, []);

  function isDateRangeOverlap(listingEvents, selectedStart, selectedEnd) {
    if (!listingEvents) return;

    for (let i = 0; i < listingEvents.length; i++) {
      const event = listingEvents[i];

      // Convert start and end strings to Date objects
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);

      // Check if event falls within the selected range
      if (eventStart <= selectedEnd && eventEnd >= selectedStart) {
        return true; // Return true if there's an overlap
      }
    }
    return false; // Return false if no overlap found
  }

  const getLocationDetails = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
      );
      const data = await response.json();
      if (data.status === "OK") {
        const address = data.results[0];
        return address;
      } else {
        return "Reverse geocoding failed:", data.status;
      }
    } catch (error) {
      return "Error fetching location details:", error;
    }
  };

  const filteredListings = listings.filter((listing) => {
    const selectedStart = dateRange[0];
    const selectedEnd = dateRange[1];
    const listingAddress = listing.listingAddress;
    const listingGeoData = listing.listingGeoData;
    const searchedGeoData = searchedLocation;
    const searchedLocationGeo = searchedLocationGeoData;

    var isChildOfSearched = true;
    var isNearbyLocation = true;
    var haveAttributes = true;
    var hasMatchingBoatTypeValue = true;
    var isAvalaible = true;
    let isBoatTypeSelected;
    let isFilterSelected;

    if (searchedGeoData != undefined) {
      if (searchedLocationGeo[0].types[0]) {
        const addressType = searchedLocationGeo[0].types[0];

        const countryComponent = listingGeoData[0].address_components.find(
          (component) => component.types.includes(addressType)
        );
        const countryComponentSearched =
          searchedLocationGeo[0].address_components.find((component) =>
            component.types.includes(addressType)
          );

        if (countryComponent) {
          const countryName = countryComponent?.long_name;
          const countryComponentSearch = countryComponentSearched?.long_name;
          if (countryName == countryComponentSearch) {
            isChildOfSearched = true;
          } else {
            isChildOfSearched = false;
          }
        } else {
          isChildOfSearched = false;
          console.log("Country not found in address components.");
        }
      }

      if (
        searchedGeoData.lat != undefined ||
        searchedGeoData.lng != undefined
      ) {
        const distance = calculateDistance(searchedLocation, listingAddress);

        if (distance < 160934) {
          isNearbyLocation = true;
        } else {
          isNearbyLocation = false;
        }
      }
    }

    if (selectedStart != undefined && selectedEnd != undefined) {
      const isOverlap = isDateRangeOverlap(
        listing.listingEvents,
        selectedStart,
        selectedEnd
      );
      if (isOverlap == true) {
        isAvalaible = false;
      }
    }

    haveAttributes =
      listing.features &&
      selectedAttributes.every((attribute) =>
        listing.features.includes(attribute)
      );

    if (selectedBoatTypes.length != 0) {
      hasMatchingBoatTypeValue = selectedBoatTypes.includes(listing.boatType);
    }

    console.log("selectedBoatTypeOptionsFilters", selectedBoatTypeOptions);

    if (selectedBoatTypeOptions.length === 0) {
      isBoatTypeSelected = true;
    } else {
      // Check if boatType exists in selectedBoatTypeOptions
      isBoatTypeSelected = selectedBoatTypeOptions.some(
        (option) => option.value === listing.boatType
      );
    }

    if (selectedFiltersOptions.length === 0) {
      isFilterSelected = true;
    } else {
      // Check if boatType exists in selectedBoatTypeOptions
      isFilterSelected = selectedFiltersOptions.some(
        (option) => option.value === listing.boatType
      );
    }

    let anyOptionSelected;

    if (selectedFiltersOptions.length === 0) {
      anyOptionSelected = true;
    } else {
      // Initialize an array to store the boolean values for each option
      const isOptionSelected = selectedFiltersOptions.map((option) => {
        // Check if the value of the option exists in listing.features
        return listing.features.includes(option.value);
      });

      // Check if any option is selected
      anyOptionSelected = isOptionSelected.includes(true);
    }

    if (isAvalaible != false) {
      console.log("Listing is available");

      if (isBoatTypeSelected != false) {
        if (anyOptionSelected != false) {
          if (searchAddress) {
            if (isNearbyLocation != false) {
              console.log("Locaton is nearby");
            } else {
              if (isChildOfSearched != false) {
                console.log("Locaton is child of searched", isChildOfSearched);
              } else {
                console.log(
                  "Locaton is not child of searched",
                  isChildOfSearched
                );
                return false;
              }
            }
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }

    return true;
  });

  // Get current listings
  const indexOfLastListing = currentPage * listingsPerPage;
  const indexOfFirstListing = indexOfLastListing - listingsPerPage;
  const currentListings = filteredListings.slice(
    indexOfFirstListing,
    indexOfLastListing
  );

  // Change page
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const containerStyle = {
    minHeight: "350px",
    borderRadius: "20px",
    padding: "3px",
    backgroundSize: "cover",
    margin: "0px",
    backgroundPosition: "center",
  };

  const getLocationName = async (lat, lng) => {
    try {
      const dropOffResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
      );

      const dropOffdata = await dropOffResponse.json();
      const dropoffName =
        dropOffdata.results[0]?.formatted_address || "Location not found";
      return dropoffName;
    } catch (error) {
      console.error("Error fetching location name:", error);
      return null;
    }
  };

  const handleCheckboxChange = (option) => {
    setSelectedAttributes((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((attr) => attr !== option)
        : [...prevSelected, option]
    );
  };

  const handleBoatTypeCheckboxChange = (option) => {
    setSelectedBoatTypes((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((attr) => attr !== option)
        : [...prevSelected, option]
    );
  };

  const handleSearchInputChange = async () => {
    setSearchAddress(grabSearchLocation);
    try {
      const results = await geocodeByAddress(grabSearchLocation);
      const latLng = await getLatLng(results[0]);
      setSearchedLocation(latLng);
      setSearchedGeoDataLocation(results);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  const filterBoatsForSelectedOptions = () => {
    setselectedBoatTypeOptions(selectedOptions);
  };

  const filterBoatsForSelectedFilters = () => {
    setSelectedFiltersOptions(grabSelectedFilterOptions);
  };

  useEffect(() => {
    if (location.state?.preselectedBoatType && boatTypeOptions.length > 0) {
      const preselectedOption = boatTypeOptions.find(
        (option) => option.value === location.state.preselectedBoatType
      );

      if (preselectedOption) {
        console.log("preselectedOption", preselectedOption);
        setselectedBoatTypeOptions([preselectedOption]);
        setSelectedOptions([preselectedOption]);
      }
    }
  }, [location.state, boatTypeOptions]);

  const [resetComplete, setResetComplete] = useState(false);

  const handleFiltersReset = () => {
    setSelectedOptions([]);
    setGrabSearchLocation("");
    setGrabSelectedFilterOptions([]);
    setSelectedDateRange([null, null]);

    setResetComplete(true); // Trigger effect once reset is complete
  };

  useEffect(() => {
    if (resetComplete) {
      handleSearchInputChange();
      filterBoatsForSelectedOptions();
      filterBoatsForSelectedFilters();
      handleFilterByDateRange();

      setResetComplete(false);
    }
  }, [resetComplete]);

  return (
    <>
      <section className="cs-bg-dark py-4 cs-section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="fw-bold text-white">Our Fleet</h1>
            </div>
          </div>
          <div
            className="row p-0"
            style={{
              borderRadius: "16px",
              overflow: "",
              backgroundColor: "#F3F3F3",
            }}
          >
            <Col
              xs={12}
              md={8}
              xl={9}
              className="p-0"
              style={{ height: "400px" }}
            >
              <div className="brtl brbl" style={{ overflow: "hidden" }}>
                {boatLocations && boatLocations.length > 0 && (
                  <>
                    <GoogleMaps boatLocations={boatLocations} />
                  </>
                )}
              </div>
            </Col>
            <Col
              xs={12}
              md={4}
              xl={3}
              className="block rounded-top-right rounded-br brtr brbr"
              style={{
                backgroundColor: "#F3F3F3",
              }}
            >
              <Row className="dateRangePicker h-100 p-3">
                <Col>
                  <h3
                    className="text-center"
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#101010",
                    }}
                  >
                    Filter Boats
                  </h3>
                </Col>
                <Col xs={12} className="mb-2" style={{ height: "fit-content" }}>
                  <div
                    className="d-flex align-items-center bg-white"
                    style={{
                      width: "100%",
                      border: "1px solid #c6c6c6",
                      outline: "none",
                      minHeight: "42px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="px-3 bg-white">
                      <PiBoatLight
                        style={{ color: "#191919", display: "inline-block" }}
                        size={18}
                      />
                    </div>
                    <div className="w-100 custom-width">
                      <MultiSelect
                        className="w-100"
                        options={boatTypeOptions}
                        value={selectedOptions}
                        onChange={setSelectedOptions}
                        disableSearch="true"
                        placeholder="By Type"
                        overrideStrings={{
                          selectSomeItems: (
                            <span style={{ color: "#191919" }}>By Type</span>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="mb-2" style={{ height: "fit-content" }}>
                  <InputGroup
                    inside
                    style={{
                      width: "100%",
                      border: "1px solid #c6c6c6",
                      outline: "none",
                      backgroundColor: "#fff",
                    }}
                  >
                    {/* Left-side icon */}
                    <InputGroup.Addon className="px-3">
                      <BsListStars
                        style={{ color: "#191919", display: "inline-block" }}
                        size={18}
                      />
                    </InputGroup.Addon>
                    <MultiSelect
                      className="w-100 custom-width"
                      options={filtersOptions}
                      value={grabSelectedFilterOptions}
                      onChange={setGrabSelectedFilterOptions}
                      disableSearch="true"
                      placeholder="By Features"
                      overrideStrings={{
                        selectSomeItems: (
                          <span style={{ color: "#191919" }}>By Features</span>
                        ),
                      }}
                    />
                  </InputGroup>
                </Col>

                <Col xs={12} className="mb-2" style={{ height: "fit-content" }}>
                  <div
                    className="d-flex align-items-center position-relative w-100 bg-white"
                    style={{
                      width: "100%",
                      border: "1px solid #c6c6c6",
                      outline: "none",
                      minHeight: "42px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="px-3">
                      <CiGlobe
                        style={{ color: "#191919", display: "inline-block" }}
                        size={18}
                      />
                    </div>
                    <div className="">
                      <PlacesAutocomplete
                        value={grabSearchLocation}
                        onChange={(newAddress) => {
                          setGrabSearchLocation(newAddress);
                        }}
                        className="form-control form-control-solid-bordered flex-1 custom-width"
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div
                            className="position-absolute top-0 left-0"
                            style={{ zIndex: "10" }}
                          >
                            <div className="input-group">
                              <input
                                style={{ padding: "10px" }}
                                {...getInputProps({
                                  placeholder: "By Location",
                                  className:
                                    "location-search-input form-control border border-2 h-100 w-100 bg-transparent",
                                  name: "location",
                                })}
                              />
                            </div>
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className:
                                        "location-suggestion form-control",
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="mb-2" style={{ height: "fit-content" }}>
                  <InputGroup
                    inside
                    style={{
                      width: "100%",
                      border: "1px solid #c6c6c6",
                      outline: "none",
                    }}
                  >
                    {/* Left-side icon */}
                    <InputGroup.Addon className="px-3">
                      <CiCalendarDate
                        style={{ color: "#191919", display: "inline-block" }}
                        size={18}
                      />
                    </InputGroup.Addon>

                    {/* DateRangePicker with custom styling */}
                    <DateRangePicker
                      id="datePicker"
                      className="w-100 h-100 custom-width"
                      value={selectedDateRange}
                      onChange={handleDateRangeChange}
                      placeholder="By Date"
                      required={false}
                      // appearance="subtle"
                      showOneCalendar
                      character=" to "
                      size="xs"
                      cleanable={false}
                      ranges={[
                        {
                          label: "Today",
                          value: [today, addDays(today, 0)], // You can adjust the number of days as needed
                        },
                        {
                          label: "Next 3 Days",
                          value: [tomorrow, addDays(tomorrow, 2)], // You can adjust the number of days as needed
                        },
                        {
                          label: "Next Seven Days",
                          value: [tomorrow, addDays(today, 7)], // You can adjust the number of days as needed
                        },
                      ]}
                      disabledDate={(date) => date < yesterday}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} className="d-flex justify-content-center mt-3">
                  <Button
                    style={{
                      height: "48px",
                      width: "min(100%,275px)",
                      borderRadius: "50px",
                    }}
                    onClick={() => {
                      handleSearchInputChange();
                      filterBoatsForSelectedOptions();
                      filterBoatsForSelectedFilters();
                      handleFilterByDateRange();
                    }}
                  >
                    Find Your Boat
                  </Button>
                </Col>
                <Col xs={12} className="d-flex justify-content-center mt-3">
                  <Button
                    className="outline-none border-none"
                    style={{
                      height: "48px",
                      width: "min(100%,275px)",
                      borderRadius: "50px",
                      backgroundColor: "rgba(252, 252, 252, 0.7)",
                      color: "#191919",
                      border: "none",
                      outline: "none",
                      background: "#fff",
                    }}
                    onClick={() => {
                      handleFiltersReset();
                    }}
                  >
                    Reset <GrPowerReset />
                  </Button>
                </Col>
              </Row>
            </Col>
            {/* <Col xs={12} md={6}>
                            <Row className='mb-3'>
                                <Col xs={12} md={6} className='mt-4'>
                                        <div className="card p-4 filter-card">
                                            <div className="attributes">
                                                <h5>Filter by boat types</h5>
                                                {boatTypesFilters.map((option, index) => (
                                                    <div className="form-check" key={index}>
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`boatTypeCheckbox-${index}${option}`}
                                                        value={option}
                                                        onChange={() => handleBoatTypeCheckboxChange(option)}
                                                        checked={selectedBoatTypes.includes(option)}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`boatTypeCheckbox-${index}${option}`}
                                                      >
                                                        {option}
                                                      </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                </Col>
                                <Col xs={12} md={6} className='mt-4'>
                                        <div className="card p-4 filter-card">
                                            <div className="attributes">
                                                <h5>Filter by boat features</h5>
                                                <Row>
                                                    <Col>
                                                        {boatFilters.map((option, index) => (
                                                            <div className="form-check" key={index} >
                                                              <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={`checkbox-${index}`}
                                                                value={option}
                                                                onChange={() => handleCheckboxChange(option)}
                                                                checked={selectedAttributes.includes(option)}
                                                              />
                                                              <label
                                                                className="form-check-label"
                                                                htmlFor={`checkbox-${index}`}
                                                              >
                                                                {option}
                                                              </label>
                                                            </div>
                                                        ))}
                                                    </Col>
                                                </Row>
                                                
                                            </div>
                                        </div>
                                </Col>
                            </Row>
                        </Col> */}
          </div>
          <div className="row mt-3">
            <Row className="boatListings mt-3">
              <Col>
                {isBoatsLoading ? (
                  <Row>
                    {[...Array(9)].map((_, i) => (
                      <Col md={4} key={i} className="mb-4 px-3">
                        <Skeleton
                          sx={{
                            bgcolor: "grey.700",
                            width: "100%",
                            height: "300px",
                          }}
                          variant="rectangular"
                        />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <Row>
                    {currentListings.map((listing) => (
                      <Col md={4} className="mb-4" key={listing.id}>
                        <Link
                          to={`/listing/${listing.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div
                            className="row productCard p-3 position-relative"
                            style={{
                              ...containerStyle,
                              backgroundImage: `url(${listing.featured_image})`,
                            }}
                          >
                            <div
                              className="overlay"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                content: '""',
                                backgroundColor: "rgba(0, 0, 0, 0.22)", // Adjust the color and opacity as needed
                              }}
                            ></div>
                            <div
                              className="col-12"
                              style={{
                                justifyContent: "space-between",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <p></p>
                              <div className="row" style={{ zIndex: "9" }}>
                                <div className="col-12"></div>
                                <div className="row">
                                  <h3
                                    style={{ padding: "0px", color: "white" }}
                                  >
                                    {listing.listingTitle}
                                  </h3>
                                  <p style={{ padding: "0px", color: "white" }}>
                                    {locationNames[listing.id] || "Loading..."}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                )}

                {/* Pagination */}
                <div className="pagination">
                  <button
                    onClick={prevPage}
                    className={`btn ${
                      currentPage === 1 ? "btn-light" : "btn-light"
                    } m-1`}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    onClick={nextPage}
                    className={`btn ${
                      currentListings.length < listingsPerPage
                        ? "btn-light"
                        : "btn-light"
                    } m-1`}
                    disabled={currentListings.length < listingsPerPage}
                  >
                    Next
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
}
